import React from "react";
import { Heading, Text, Button } from "@chakra-ui/react";
import useVersionCheck from "hooks/useVersionCheck";
import { FullPageSplash } from "components/FullPageSplash";
import { CardContainer } from "components/CardContainer";

interface Props {}

export const ReloadApp = (props: Props) => {
  const { doReload } = useVersionCheck();
  return (
    <FullPageSplash>
      <CardContainer>
        <Heading marginBottom="2">Updates Available</Heading>
        <Text mb="2">A New version of the app has been released, please reload to update. </Text>
        <Button colorScheme="blue" onClick={doReload}>
          Reload
        </Button>
      </CardContainer>
    </FullPageSplash>
  );
};
