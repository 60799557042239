export function resizeImage(files: File[], uploadHandler: Function) {
  const uploadFile = files[0];
  const MAX_WIDTH = 800;
  const reader = new FileReader();
  if (reader && uploadFile) {
    const img = new Image();
    reader.onloadend = (e) => {
      if (e.target?.result) {
        img.src = e.target.result?.toString();
      }
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (img.width > MAX_WIDTH) {
          const aspectRatio = MAX_WIDTH / img.width;
          img.width = MAX_WIDTH;
          img.height *= aspectRatio;
        }
        canvas.height = img.height;
        canvas.width = img.width;
        ctx?.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(
          function (blob) {
            if (blob) uploadHandler([new File([blob], uploadFile.name)]);
          },
          uploadFile.type,
          1
        );
      };
    };
  }
  reader.readAsDataURL(uploadFile);
}
