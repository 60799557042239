import { Box, BoxProps, Heading, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ERoutes } from "Router/types";
import { ISearch } from "types";
import { SearchHeading } from "./SearchHeading";

interface Props extends BoxProps {
  occasions: ISearch["occasions"];
}

export const Occasions = ({ occasions, ...boxProps }: Props) => {
  const { t } = useTranslation();
  if (occasions.length === 0) return null;
  return (
    <Box {...boxProps}>
      <SearchHeading>{t("Events")}</SearchHeading>
      {occasions.map((occasion) => (
        <LinkBox key={occasion.occasionId} _hover={{ bg: "gray.200" }} px="1" py="2">
          <Text fontWeight="semibold">
            <LinkOverlay as={Link} to={`${ERoutes.events}/${occasion.occasionId}`}>
              {occasion.occassionName}
            </LinkOverlay>
          </Text>
          <Text fontSize="small">{occasion.companyName}</Text>
        </LinkBox>
      ))}
    </Box>
  );
};
