import {
  Box,
  Container,
  HStack,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useUserSelf } from "api/user";
import { ErrorFallback } from "components/ErrorFallback";
import { Search } from "components/Search";
import { useGoogleAnalyticsEventLogger } from "hooks/useGoogleAnalyticsEventLogger";
import React, { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { UserRoles } from "types";
import ABFTopBar from "./ABFTopBar";
import logo100 from "./ABF_Logo_100.png";
import MenuLeft from "./MenuLeft";

interface Props {}

export const PageCtx = React.createContext<{ scrollToTop: () => void }>({
  scrollToTop: () => null,
});

const Layout = (props: PropsWithChildren<Props>) => {
  const showSideBar = useBreakpointValue({ base: false, md: true });
  const QUser = useUserSelf();
  const canSearch =
    QUser.data?.role.id === UserRoles.Admin ||
    QUser.data?.role.id === UserRoles.Member;
  const pageRef = React.useRef<HTMLDivElement | null>(null);
  const scrollToTop = () => {
    pageRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  useGoogleAnalyticsEventLogger(document.title ?? "unknown", "page_view");

  return (
    <>
      <Box>
        <VStack h="100vh" w="100%" spacing={0} bg="gray.100" overflow="hidden">
          <Box h={showSideBar ? 16 : 24} m={0} w="100%" bg="white">
            <Container
              h="100%"
              maxW="container.xl"
              flex={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box p={1}>
                <img src={logo100} alt="Associated British Foods" />
              </Box>
              <Box flexGrow={1} maxW="600px" my="2">
                {canSearch ? <Search /> : null}
              </Box>
              <Box display="flex" flexDirection="column">
                <ABFTopBar />
              </Box>
            </Container>
          </Box>
          <Box h={10} m={0} w="100%" bg="brand.red">
            &nbsp;
          </Box>
          <Box h={2.5} m={0} w="100%" bg="brand.grey">
            &nbsp;
          </Box>
          <Container maxW="container.xl" flex={1} height="100%">
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => window.location.reload()}
            >
              <HStack h="100%" spacing={0}>
                {showSideBar && <MenuLeft />}
                <Box
                  ref={pageRef}
                  padding="4"
                  pb="8"
                  bg="gray.100"
                  flex={1}
                  h="calc(100vh - 88px)"
                  overflowY="auto"
                  id="page-wrapper"
                >
                  <PageCtx.Provider value={{ scrollToTop }}>
                    {props.children}
                  </PageCtx.Provider>
                </Box>
              </HStack>
            </ErrorBoundary>
          </Container>
        </VStack>
      </Box>
    </>
  );
};

export default Layout;
