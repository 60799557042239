import React from "react";
import { useParams } from "react-router-dom";

interface Props {}

const Event = (props: Props) => {
  const { eventId } = useParams<{ eventId: string }>();
  return <div id="event-wrapper">Event page for {eventId}</div>;
};

export default Event;
