import { useTranslation } from "react-i18next";
import { ModerationStatus } from "types";

export function useEditButtonText(mode?: number | null): { submitText: string; saveDraftText: string } {
  const { t } = useTranslation();
  const defaultValue = { submitText: t("Submit"), saveDraftText: t("Save as Draft") };
  if (!mode) {
    return defaultValue;
  }
  return mode === ModerationStatus.Private
    ? { submitText: t("Submit"), saveDraftText: t("Update Draft") }
    : mode === ModerationStatus.Pending
    ? { submitText: t("Update"), saveDraftText: t("Move to Drafts") }
    : mode === ModerationStatus.Rejected
    ? { submitText: t("Resubmit"), saveDraftText: t("Move to drafts") }
    : defaultValue;
}
