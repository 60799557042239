import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "lib/axios";
import { useToast } from "@chakra-ui/toast";
import { useTranslation } from "react-i18next";
import { GUID, IAuthorContact, INewsFeed, INewsFeedItemDetail, UserRoles } from "types";
import { cleanParamsLoop } from "lib/objectHelpers";
import QueryString from "qs";
import { useErrorToast } from "hooks/useErrorToast";
import { AxiosError } from "axios";
import { useUserSelf } from "./user";

export interface NewsFeedParams {
  pageSize?: number;
  pageNumber: number;
  favouriteSuppliersOnly?: boolean;
  includeMembers?: boolean;
  includeSuppliers?: boolean;
  regions: string[];
  sortOrder: "ASC" | "DESC";
}

function useNewsFeed(params: NewsFeedParams) {
  let cleanedParams = cleanParamsLoop(params);
  const QUser = useUserSelf();
  return useQuery(
    ["NewsFeed", params],
    (): Promise<INewsFeed> =>
      axios.get("User/newsfeed", {
        params: cleanedParams,
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: "repeat" }),
      }),
    {
      keepPreviousData: true,
      enabled: QUser.data?.role.id === UserRoles.Member || QUser.data?.role.id === UserRoles.Admin,
    }
  );
}

export interface ICreatePostBody {
  companyId: GUID;
  title: string;
  description: string;
  isDraft: boolean;
  regions: GUID[];
  content: string;
  thumbnailBase64: string;
}

function useCreateNewsPost() {
  const { t } = useTranslation();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation((post: ICreatePostBody) => axios.post("NewsPost", post), {
    onSuccess: () => {
      toast({
        position: "bottom-left",
        status: "success",
        title: t("Success"),
      });
      queryClient.invalidateQueries(["NewsFeed"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

export interface IEditPostBody {
  id: GUID;
  title: string;
  description: string;
  isDraft: boolean;
  content: string;
  contentChanged: boolean;
  thumbnailBase64: string;
  thumbnailChanged: boolean;
  comment: string;
}

function useEditNewsItem() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorToast = useErrorToast();
  return useMutation((post: IEditPostBody) => axios.put(`Newspost/${post.id}`, post), {
    onSuccess: (res, variables) => {
      toast({
        position: "bottom-left",
        status: "success",
        title: t("Success"),
      });
      queryClient.invalidateQueries(["NewsFeed"]);
      queryClient.invalidateQueries(["NewsFeed", variables.id]);
      queryClient.invalidateQueries(["Moderation"]);
      queryClient.invalidateQueries("Moderation Queue");
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useDeletePost() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorToast = useErrorToast();
  return useMutation((postId: string) => axios.delete(`NewsPost/${postId}`), {
    onSuccess: (res, id) => {
      toast({
        position: "bottom-left",
        status: "success",
        title: t("Success"),
      });
      queryClient.invalidateQueries(["NewsFeed"]);
      queryClient.invalidateQueries(["NewsFeed", id]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useNewsItem(itemId?: string) {
  return useQuery(["Newsfeed", itemId], (): Promise<INewsFeedItemDetail> => axios.get(`NewsPost/${itemId}`), {
    enabled: !!itemId,
  });
}

interface NewsPostRegionEditBody {
  newsPostId: GUID;
  regionId: GUID;
}

function useAddNewsPostRegion() {
  const queryClient = useQueryClient();
  return useMutation((body: NewsPostRegionEditBody) => axios.post(`NewsPost/${body.newsPostId}/regions/add`, body), {
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries(["Newsfeed", variables.newsPostId]);
    },
  });
}
function useRemoveNewsPostRegion() {
  const queryClient = useQueryClient();

  return useMutation((body: NewsPostRegionEditBody) => axios.post(`NewsPost/${body.newsPostId}/regions/remove`, body), {
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries(["Newsfeed", variables.newsPostId]);
    },
  });
}

function useAuthorDetails(authorId?: GUID) {
  return useQuery(["Author", authorId], (): Promise<IAuthorContact> => axios.get(`NewsPost/author/${authorId}`), {
    enabled: !!authorId,
  });
}

export {
  useNewsFeed,
  useCreateNewsPost,
  useNewsItem,
  useEditNewsItem,
  useDeletePost,
  useAddNewsPostRegion,
  useRemoveNewsPostRegion,
  useAuthorDetails,
};
