import useVersionCheck from "hooks/useVersionCheck";
import { ReloadApp } from "Layout/ReloadApp";
import LegalProvider from "Providers/Legal";
import React from "react";
import Layout from "./Layout";
import Routes from "./Router/Routes";

export function App() {
  const { correct } = useVersionCheck();

  return correct ? (
    <LegalProvider>
      <Layout>
        <Routes />
      </Layout>
    </LegalProvider>
  ) : (
    <ReloadApp />
  );
}

export default App;
