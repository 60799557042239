import { Box, Button, Text } from "@chakra-ui/react";
import { useEditProfile, useProfile } from "api/profile";
import { EditEntityMeta } from "components/EditEntityMeta";
import { useEditButtonText } from "lib/editButtonText";
import { resizeImage } from "lib/resizeImage";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SunEditor from "suneditor-react";
import lang from "suneditor-react/dist/types/lang";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import SunEditorCore from "suneditor/src/lib/core";
import { ICompanyProfile } from "types";

interface Props {
  profileId: ICompanyProfile["id"];
}

const EditProfile = ({ profileId }: Props) => {
  const { i18n } = useTranslation();
  const QProfile = useProfile(profileId);
  const editProfile = useEditProfile();
  let editorRef = useRef<SunEditorCore | null>(null);
  const buttonText = useEditButtonText(QProfile.data?.proposedProfile?.moderationStatus?.id);
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editorRef.current = sunEditor;
  };

  useEffect(() => {
    if (QProfile.data) {
      editorRef.current?.setContents(
        QProfile.data.proposedProfile?.content || QProfile.data.approvedProfile.content || ""
      );
      editorRef.current?.setOptions({});
    }
  }, [QProfile.data]);

  function handleSubmitProfile() {
    editProfile.mutate({
      id: profileId,
      content: editorRef.current?.getContents(true) ?? "",
      isDraft: false,
    });
  }

  function handleSaveAsDraft() {
    editProfile.mutate({
      id: profileId,
      content: editorRef.current?.getContents(true) ?? "",
      isDraft: true,
    });
  }

  const { t } = useTranslation();
  return (
    <Box>
      <EditEntityMeta meta={QProfile.data?.proposedProfile} />

      <SunEditor
        lang={i18n.language as lang}
        height="100%"
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
              "fontColor",
              "hiliteColor",
              "removeFormat",
            ],
            ["align", "horizontalRule", "outdent", "indent", "list"],
            ["table", "link", "image", "video"],
          ],
        }}
        onImageUploadBefore={(files, _, uploadHandler) => {
          resizeImage(files, uploadHandler);
        }}
        getSunEditorInstance={getSunEditorInstance}
        name="textContent"
      />
      <Box my={4} justifyContent="flex-end" display="flex">
        <Button onClick={handleSaveAsDraft}>{t(buttonText.saveDraftText)}</Button>
        <Button ml={4} onClick={handleSubmitProfile}>
          {buttonText.submitText}
        </Button>
      </Box>
    </Box>
  );
};

export { EditProfile };
