import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { Box, ButtonGroup, IconButton, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useDeleteContact } from "api/contacts";
import { ConfirmDeleteModal } from "components/ConfirmDeleteModal";
import { useIsOwner } from "hooks/useIsOwner";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICompanyContact } from "types";
import EditContactModal from "../../../components/Entitites/contact/EditContactModal";

interface Props {
  data: ICompanyContact[];
  companyId: string;
}

function ContactTable({ data, companyId }: Props) {
  const { t } = useTranslation();
  const isOwner = useIsOwner(companyId);
  const [selectedContact, setSelectedContact] = React.useState<ICompanyContact | null>(null);
  const [contactToDelete, setContactToDelete] = React.useState<ICompanyContact["id"] | null>(null);
  const deleteContact = useDeleteContact();
  return (
    <Box overflowY="scroll">
      <Table>
        <Thead>
          <Tr>
            <Th>{t("Name")}</Th>
            <Th>{t("Job Title")}</Th>
            <Th>{t("Telephone")}</Th>
            <Th>{t("Region")}</Th>
            <Th>{t("Email")}</Th>
            {isOwner && <Th />}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((contact) => (
            <Tr key={contact.id}>
              <Td>{contact.name}</Td>
              <Td>{contact.jobTitle}</Td>
              <Td>{contact.telephone}</Td>
              <Td>{contact.geography}</Td>
              <Td>{contact.emailAddress}</Td>
              {isOwner ? (
                <Td>
                  <ButtonGroup>
                    <ButtonGroup spacing="4">
                      <IconButton aria-label={`edit`} variant="ghost" onClick={() => setSelectedContact(contact)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" variant="ghost" onClick={() => setContactToDelete(contact.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ButtonGroup>
                  </ButtonGroup>
                </Td>
              ) : null}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ConfirmDeleteModal
        entity={"Contact"}
        deleteKey={contactToDelete}
        handleClose={() => setContactToDelete(null)}
        key="id"
        deleteFunction={deleteContact}
      />
      {selectedContact ? (
        <EditContactModal
          contact={selectedContact}
          open={!!selectedContact}
          handleClose={() => setSelectedContact(null)}
        />
      ) : null}
    </Box>
  );
}

export { ContactTable };
