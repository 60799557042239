import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "lib/axios";
import { GUID, IDocument, IDocumentSettings } from "types";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { downloadByteArray } from "lib/downloadByteArray";
import { useErrorToast } from "hooks/useErrorToast";

function useDocument(id: GUID) {
  return useQuery(["Document", id], (): Promise<IDocument> => axios.get(`Document/${id}`));
}

function useCreateDocument() {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((doc: FormData) => axios.post("Document", doc), {
    onSuccess: () => {
      toast({
        title: t("Document Created"),
        description: t("The Documennt has been uploaded."),
        status: "success",
        isClosable: true,
        position: "bottom-left",
      });
      queryClient.invalidateQueries(["Documents"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useDeleteDocument() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const errorToast = useErrorToast();
  return useMutation((docId: string) => axios.delete(`Document/${docId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries("documents");
      queryClient.invalidateQueries(["Company", "Documents"]);
      toast({
        position: "bottom-left",
        status: "success",
        title: t("Success"),
        description: t("Document has been deleted"),
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

interface IEditDocumentPut {
  id: string;
  title: string;
  description: string;
  comment: string;
}
function useEditDocument() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const errorToast = useErrorToast();
  return useMutation((document: IEditDocumentPut) => axios.put(`Document/${document.id}`, document), {
    onSuccess: () => {
      queryClient.invalidateQueries("documents");
      queryClient.invalidateQueries(["Moderation"]);
      toast({
        position: "bottom-left",
        status: "success",
        title: t("Success"),
        description: t("Document has been updated"),
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

interface IDownloadPost {
  documentId: string;
  fileName: string;
  proposed?: boolean;
}

function useDownloadDocument() {
  const errorToast = useErrorToast();
  return useMutation(
    (doc: IDownloadPost): Promise<Uint8Array> =>
      axios.get(`Document/${doc.documentId}/download${doc.proposed ? "/proposed" : ""}`, {
        responseType: "arraybuffer",
      }),
    {
      onSuccess: (res, variables) => {
        downloadByteArray(variables.fileName, res);
      },
      onError: (err: AxiosError) => {
        errorToast(err);
      },
    }
  );
}

function useDocumentSettings() {
  return useQuery(["Document", "settings"], (): Promise<IDocumentSettings> => axios.get(`Document/settings`));
}

export { useCreateDocument, useDeleteDocument, useEditDocument, useDownloadDocument, useDocument, useDocumentSettings };
