import {
  Badge,
  Box,
  Heading,
  HStack,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { useCompany } from "api/company";
import { useIsFavouriteCompany, useUserSelf } from "api/user";
import FavouriteCompanyToggle from "components/FavouriteCompanyToggle/FavouriteCompanyToggle";
import { LoadingError } from "components/LoadingError";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useGoogleAnalyticsEventLogger } from "hooks/useGoogleAnalyticsEventLogger";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ICompanyDetail, UserRoles } from "types";
import SupplierContacts from "./contacts";
import { CompanyDocuments } from "./documents";
import SupplierEvents from "./events";
import { CompanyNewsfeed } from "./news";
import Profile from "./profile";
import { CompanyValueTracker } from "./valueTracker/CompanyValueTracker";

interface Props {
  initialTab?: EProfileTabs;
}

export enum EProfileTabs {
  Proile,
  Contacts,
  Events,
  News,
  Documents,
}

export const SupplierPage = ({ initialTab = EProfileTabs.Proile }: Props) => {
  const { t } = useTranslation();
  const QUser = useUserSelf();
  const { supplierId } = useParams<{ supplierId: string }>();
  const QCompanyInfo = useCompany(supplierId);
  const isFavouriteCompany = useIsFavouriteCompany(supplierId);
  const shouldDisplayValueTracker =
    !!QUser.data?.valueTrackerAccess || QUser.data?.role.id === 10;

  useGoogleAnalyticsEventLogger(
    QCompanyInfo.data?.name ?? "unknown",
    "supplier_page_view"
  );

  return (
    <Box>
      <Box bg="white" px="4" py="8" mb="8" borderRadius="xl">
        {QCompanyInfo.isLoading || QCompanyInfo.isIdle ? (
          <LoadingSpinner />
        ) : QCompanyInfo.isError ? (
          <LoadingError />
        ) : (
          <>
            {QUser.data?.role.id === UserRoles.Member ? (
              <HStack justifyContent="flex-end">
                <FavouriteCompanyToggle
                  isFavourite={isFavouriteCompany}
                  companyId={QCompanyInfo.data.id}
                />
              </HStack>
            ) : null}
            <HStack justifyContent="space-between">
              <Image src={QCompanyInfo.data.thumbnailBase64} maxW="128px" />
              <Box>
                <Heading maxWidth="23ch">{QCompanyInfo.data?.name}</Heading>
                <Wrap mt="3" spacing="2">
                  {QCompanyInfo.data?.companyCategories.map((category) => (
                    <Badge
                      fontSize="lg"
                      key={category.id}
                      bgColor="brand.red"
                      color="white"
                    >
                      {category.name}
                    </Badge>
                  ))}
                </Wrap>
              </Box>
              <AddressDisplay
                address={QCompanyInfo.data?.companyAddresses?.[0]}
              />
            </HStack>
          </>
        )}
      </Box>
      <Box bg="white" px="4" py="8" rounded="lg">
        <Tabs variant="enclosed" size="lg" isLazy defaultIndex={initialTab}>
          <TabList pt="1" overflowX="scroll" overflowY="hidden">
            <Tab>{t("Profile")}</Tab>
            <Tab>{t("Contacts")}</Tab>
            <Tab>{t("Events")}</Tab>
            <Tab>{t("News")}</Tab>
            <Tab>{t("Documents")}</Tab>
            {shouldDisplayValueTracker && <Tab>{t("Value Tracker")}</Tab>}
          </TabList>
          <TabPanels>
            <TabPanel>
              <Profile companyId={supplierId} />
            </TabPanel>
            <TabPanel>
              <SupplierContacts companyId={supplierId} />
            </TabPanel>

            <TabPanel>
              <SupplierEvents companyId={supplierId} />
            </TabPanel>
            <TabPanel>
              <CompanyNewsfeed companyId={supplierId} />
            </TabPanel>
            <TabPanel>
              <CompanyDocuments companyId={supplierId} />
            </TabPanel>
            {shouldDisplayValueTracker && (
              <TabPanel>
                <CompanyValueTracker companyId={supplierId} />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

interface AddressDisplayProps {
  address?: ICompanyDetail["companyAddresses"]["0"];
}

export function AddressDisplay({ address }: AddressDisplayProps) {
  if (!address) return null;

  return (
    <Box textAlign="right" fontWeight="bold">
      <Text>{address.address1}</Text>
      <Text>{address.address2}</Text>
      <Text>{address.address3}</Text>
      <Text>{address.address4}</Text>
      <Text>{address.postCodeZip}</Text>
      <Text>{address.countyState}</Text>
      <Text>{address.countryName}</Text>
    </Box>
  );
}
