import { Box, Heading, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Image } from "@chakra-ui/react";
import { useCompany } from "api/company";
import { LoadingError } from "components/LoadingError";
import { LoadingSpinner } from "components/LoadingSpinner";
import React from "react";
import { useTranslation } from "react-i18next";
import { GUID } from "types";
import SupplierContacts from "./contacts";
import { CompanyDocuments } from "./documents";
import { SupplierEventsScreen } from "./events/SupplierEventsScreen";
import { CompanyNewsfeed } from "./news";
import Profile from "./profile";
import { AddressDisplay } from "./SupplierPage";
import { CompanyValueTracker } from "./valueTracker/CompanyValueTracker";

interface Props {
  initialTab?: EProfileTabs;
  id: GUID;
}

export enum EProfileTabs {
  Proile,
  Contacts,
  Events,
  News,
  Documents,
}

export const MyProfile = ({ initialTab = EProfileTabs.Proile, id }: Props) => {
  const { t } = useTranslation();

  const QCompanyInfo = useCompany(id);
  return (
    <>
      {QCompanyInfo.isLoading || QCompanyInfo.isIdle ? (
        <LoadingSpinner />
      ) : QCompanyInfo.isError ? (
        <LoadingError />
      ) : (
        <Box>
          <Box bg="white" px="4" py="8" mb="8">
            <>
              <HStack justifyContent="space-between">
                <Image src={QCompanyInfo.data?.thumbnailBase64} maxW="128px" />
                <Heading maxWidth="23ch">{QCompanyInfo.data?.name}</Heading>
                <AddressDisplay address={QCompanyInfo.data?.companyAddresses?.[0]} />
              </HStack>
            </>
          </Box>
          <Box bg="white" px="4" py="8" rounded="lg">
            <Tabs variant="enclosed" size="lg" isLazy defaultIndex={initialTab}>
              <TabList pt="1" overflowX="scroll" overflowY="hidden">
                <Tab>{t("Profile")}</Tab>
                <Tab>{t("Contacts")}</Tab>
                <Tab>{t("Events")}</Tab>
                <Tab>{t("News")}</Tab>
                <Tab>{t("Documents")}</Tab>
                <Tab>{t("Value Tracker")}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Profile companyId={QCompanyInfo.data.id} />
                </TabPanel>
                <TabPanel>
                  <SupplierContacts companyId={QCompanyInfo.data.id} />
                </TabPanel>

                <TabPanel>
                  <SupplierEventsScreen companyId={QCompanyInfo.data.id} />
                </TabPanel>
                <TabPanel>
                  <CompanyNewsfeed companyId={QCompanyInfo.data.id} />
                </TabPanel>
                <TabPanel>
                  <CompanyDocuments companyId={QCompanyInfo.data.id} />
                </TabPanel>
                <TabPanel>
                  <CompanyValueTracker companyId={QCompanyInfo.data.id} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      )}
    </>
  );
};
