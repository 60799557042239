import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

function useErrorToast() {
  const { t } = useTranslation();
  const toast = useToast();

  return function errorToast(err: AxiosError<any>) {
    if (err.response?.status === 409) {
      toast({
        status: "error",
        position: "bottom-left",
        title: t("Error"),
        description: err.response?.data?.detail,
      });
    } else if (err.response?.status === 400) {
      toast({
        status: "error",
        position: "bottom-left",
        title: t("Error"),
        description: err.response?.data?.detail,
      });
    } else {
      toast({
        status: "error",
        position: "bottom-left",
        title: t("Error"),
        description: t("Something went wrong, please try again."),
      });
    }
  };
}

export { useErrorToast };
