import { useEffect, useRef, useState, useCallback } from "react";
import Axios from "axios";

const useVersionCheck = () => {
  const [init, setInit] = useState<boolean>(false);
  const version = useRef<string>();

  const fetchVersion = useCallback(() => {
    Axios.get("/version.json", {
      headers: {
        Accept: "application/json",
        "Cache-Control": "max-age=0 no-cache no-store must-revalidate max-stale=0 post-check=0 pre-check=0",
        Pragma: "no-cache",
        Vary: "*",
        Expires: "Mon, 26 Jul 1997 05:00:00 GMT",
      },
    })
      .then((res) => res.data)
      .then((res) => {
        version.current = res.v;
        if (!init) setInit(true);
      });
  }, [init]);

  useEffect(() => {
    fetchVersion();
    const timer = setInterval(fetchVersion, 1000 * 60 * 5);
    return () => {
      clearInterval(timer);
    };
  }, [fetchVersion]);

  const doReload = () => window.location.reload();

  return {
    initDone: init,
    correct: version.current ? version.current === process.env.REACT_APP_VERSION : true,
    version: version.current,
    fetchVersion,
    doReload,
  };
};

export default useVersionCheck;
