import { useUserSelf } from "api/user";
import { LoadingError } from "components/LoadingError";
import { LoadingSpinner } from "components/LoadingSpinner";

import Post from "components/Entitites/post";
import { MyProfile } from "pages/Supplier/MyProfile";
import { EProfileTabs } from "pages/Supplier/SupplierPage";
import { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { GUID, UserRoles } from "types";
import Event from "../components/Entitites/event";
import { ERoutes } from "./types";
import React from "react";

// Supplier & Member Pending
const PendingUser = lazy(() => import("pages/PendingUser"));
const AddPostMember = lazy(
  () => import("components/Entitites/post/AddPost/AddPostMember")
);

const EditPostData = lazy(
  () => import("components/Entitites/post/EditPost/EditPostData")
);
const Events = lazy(() => import("../pages/Events"));
const NewsFeed = lazy(() => import("../pages/NewsFeed"));
const SupplierPage = lazy(() => import("../pages/Supplier/"));
const Suppliers = lazy(() => import("../pages/Suppliers"));
const Logout = lazy(() => import("../pages/Logout"));
const EventDetail = lazy(
  () => import("components/Entitites/event/EventDetail")
);
const EditEvent = lazy(() => import("components/Entitites/event/EditEvent"));
const UserDrafts = lazy(() => import("pages/PendingUser/PendingDrafts"));
const Rejections = lazy(() => import("pages/PendingUser/Rejections"));

// ADMIN Routes
const AdminNavigation = lazy(() => import("pages/admin/AdminNavigation"));
const AddCompany = lazy(() => import("pages/admin/AddCompany"));
const EditCompany = lazy(() => import("pages/admin/Company"));
const CategoryManagement = lazy(() => import("pages/admin/CategoryManagement"));
const CountryManagement = lazy(() => import("pages/admin/CountryManagement"));
const RegionManagement = lazy(() => import("pages/admin/RegionManagement"));
const MemberSiteManagement = lazy(
  () => import("pages/admin/MemberSiteManagement")
);
const AdminUserMangement = lazy(
  () => import("pages/admin/SystemUserManagement/UserManagement")
);
const SearchUsers = lazy(() => import("pages/admin/SearchUsers/SearchUsers"));
const InvitationStatus = lazy(() => import("pages/admin/InvitationStatus"));
const AllCompaniesValueTracker = lazy(
  () => import("pages/Supplier/valueTracker/AllValueTrackers")
);

// MODERATOR ROUTES
const Moderation = lazy(() => import("pages/moderation"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const Terms = lazy(() => import("pages/TermsAndConditions"));
const Routes = () => {
  const QUser = useUserSelf();

  if (QUser.isLoading) return <LoadingSpinner />;
  if (!QUser.isSuccess) return <LoadingError />;

  if (QUser.data.role.id === UserRoles.Supplier) {
    return <SupplierUserRoutes companyId={QUser.data.userCompany.companyId} />;
  }
  if (QUser.data.role.id === UserRoles.Member) {
    return <MemberUserRoutes />;
  }
  if (QUser.data.role.id === UserRoles.Moderator) {
    return <ModeratorRoutes />;
  }
  if (QUser.data.role.id === UserRoles.Admin) {
    return <AdminRoutes />;
  }
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route path={ERoutes.root} exact>
          <Logout />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;

interface SupplierUserRoutesProps {
  companyId: GUID;
}

function SupplierUserRoutes({ companyId }: SupplierUserRoutesProps) {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Switch>
        <Route path={ERoutes.pending}>
          <PendingUser />
        </Route>
        <Route path={ERoutes.drafts}>
          <UserDrafts />
        </Route>
        <Route path={ERoutes.rejected}>
          <Rejections />
        </Route>
        <Route path={`${ERoutes.events}/add`}>
          <Event />
        </Route>
        <Route path={`${ERoutes.events}/:eventId/edit`}>
          <EditEvent />
        </Route>
        <Route path={`${ERoutes.events}/:eventId`}>
          <EventDetail />
        </Route>
        <Route path="/newsfeed/:postId/edit">
          <EditPostData />
        </Route>
        <Route path="/newsfeed/:postId">
          <Post />
        </Route>
        <Route path={ERoutes.logout}>
          <Logout />
        </Route>
        <Route path={ERoutes.terms}>
          <Terms />
        </Route>
        <Route path={ERoutes.privacyPolicy}>
          <PrivacyPolicy />
        </Route>
        <Route path={ERoutes.root} exact>
          <MyProfile id={companyId} initialTab={EProfileTabs.Proile} />
        </Route>
        <Redirect to={ERoutes.root} />
      </Switch>
    </Suspense>
  );
}

function MemberUserRoutes() {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Switch>
        <Route path={ERoutes.suppliers}>
          <Suppliers />
        </Route>
        <Route path={ERoutes.pending}>
          <PendingUser />
        </Route>
        <Route path={ERoutes.drafts}>
          <UserDrafts />
        </Route>
        <Route path={ERoutes.rejected}>
          <Rejections />
        </Route>
        <Route path="/supplier/:supplierId/events">
          <SupplierPage initialTab={EProfileTabs.Events} />
        </Route>
        <Route path={`${ERoutes.supplier}/:supplierId/contacts`}>
          <SupplierPage initialTab={EProfileTabs.Contacts} />
        </Route>
        <Route path={`${ERoutes.supplier}/:supplierId/newsfeed`}>
          <SupplierPage initialTab={EProfileTabs.News} />
        </Route>
        <Route path={`${ERoutes.supplier}/:supplierId/documents`}>
          <SupplierPage initialTab={EProfileTabs.Documents} />
        </Route>
        <Route path={`${ERoutes.supplier}/:supplierId`}>
          <SupplierPage />
        </Route>
        <Route path={ERoutes.supplier} exact>
          <Redirect to="/suppliers" />
        </Route>
        <Route path={`${ERoutes.newsfeed}/:postId/edit`}>
          <EditPostData />
        </Route>
        <Route path={`${ERoutes.newsfeed}/add`}>
          <AddPostMember />
        </Route>
        <Route path={`${ERoutes.newsfeed}/:postId`}>
          <Post />
        </Route>
        <Route path={`${ERoutes.newsfeed}`}>
          <NewsFeed />
        </Route>
        <Route path={`${ERoutes.events}/:eventId`}>
          <EventDetail />
        </Route>
        <Route path={ERoutes.events}>
          <Events />
        </Route>
        <Route path={ERoutes.logout}>
          <Logout />
        </Route>
        <Route path={ERoutes.suppliers}>
          <Suppliers />
        </Route>
        <Route path={ERoutes.terms}>
          <Terms />
        </Route>
        <Route path={ERoutes.allCompanyValueTracker}>
          <AllCompaniesValueTracker />
        </Route>
        <Route path={ERoutes.privacyPolicy}>
          <PrivacyPolicy />
        </Route>
        <Route path={ERoutes.root} exact={true}>
          <NewsFeed />
        </Route>
      </Switch>
    </Suspense>
  );
}

function ModeratorRoutes() {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Switch>
        <Route path={ERoutes.logout}>
          <Logout />
        </Route>
        <Route path={ERoutes.terms}>
          <Terms />
        </Route>
        <Route path={ERoutes.privacyPolicy}>
          <PrivacyPolicy />
        </Route>
        <Route path={ERoutes.root} exact>
          <Moderation />
        </Route>

        <Redirect to={ERoutes.root} exact />
      </Switch>
    </Suspense>
  );
}

function AdminRoutes() {
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Switch>
        <Route path={ERoutes.moderation}>
          <Moderation />
        </Route>
        <Route path={ERoutes.categoryManagement}>
          <CategoryManagement />
        </Route>
        <Route path={ERoutes.regionManagement}>
          <RegionManagement />
        </Route>
        <Route path={ERoutes.memberSiteManagement}>
          <MemberSiteManagement />
        </Route>
        <Route path={ERoutes.addCompany}>
          <AddCompany />
        </Route>
        <Route path={ERoutes.editCompanies}>
          <EditCompany />
        </Route>
        <Route path={ERoutes.countryManagement}>
          <CountryManagement />
        </Route>
        <Route path={ERoutes.adminUserManagement}>
          <AdminUserMangement />
        </Route>
        <Route path={ERoutes.SearchUsers}>
          <SearchUsers />
        </Route>
        <Route path={ERoutes.admin} exact>
          <AdminNavigation />
        </Route>
        <Route path={ERoutes.invitationStatus}>
          <InvitationStatus />
        </Route>
        <Route path="/supplier/:supplierId/events/:eventId">
          <Event />
        </Route>
        <Route path="/supplier/:supplierId/newsfeed/:postId">
          <Post />
        </Route>
        <Route path="/supplier/:supplierId/events">
          <SupplierPage initialTab={EProfileTabs.Events} />
        </Route>
        <Route path="/supplier/:supplierId/contacts">
          <SupplierPage initialTab={EProfileTabs.Contacts} />
        </Route>
        <Route path="/supplier/:supplierId/newsfeed">
          <SupplierPage initialTab={EProfileTabs.News} />
        </Route>
        <Route path="/supplier/:supplierId/information">
          <SupplierPage initialTab={EProfileTabs.Documents} />
        </Route>
        <Route path="/supplier/:supplierId">
          <SupplierPage />
        </Route>
        <Route path="/supplier" exact>
          <Redirect to="/suppliers" />
        </Route>
        <Route path="/newsfeed/:postId/edit">
          <EditPostData />
        </Route>
        <Route path="/newsfeed/:postId">
          <Post />
        </Route>
        <Route path="/newsfeed">
          <NewsFeed />
        </Route>
        <Route path={`${ERoutes.events}/add`}>
          <Event />
        </Route>
        <Route path={`${ERoutes.events}/:eventId`}>
          <EventDetail />
        </Route>
        <Route path={ERoutes.events}>
          <Events />
        </Route>
        <Route path={ERoutes.terms}>
          <Terms />
        </Route>
        <Route path={ERoutes.privacyPolicy}>
          <PrivacyPolicy />
        </Route>
        <Route path={ERoutes.logout}>
          <Logout />
        </Route>
        <Route path={ERoutes.allCompanyValueTracker}>
          <AllCompaniesValueTracker />
        </Route>
        <Route path={ERoutes.suppliers}>
          <Suppliers />
        </Route>

        <Route path={ERoutes.root} exact>
          <NewsFeed />
        </Route>
        <Redirect to={ERoutes.root} exact />
      </Switch>
    </Suspense>
  );
}
