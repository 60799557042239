import { Box, Heading, HStack, LinkBox, LinkOverlay, Stack, Tag, Text, Wrap } from "@chakra-ui/react";
import React from "react";
import { ICompanyOccasion } from "types";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { ERoutes } from "Router/types";

interface Props {
  event: ICompanyOccasion;
}

const EventSimple = ({ event }: Props) => {
  const formattedDate = React.useMemo(() => format(new Date(event.startDate), "dd/MM/yyyy H:mm"), [event.startDate]);
  return (
    <LinkBox>
      <Box py={6} px={4} bg="white" shadow="md" rounded="lg">
        <Stack>
          <Heading as="h3" size="md">
            <LinkOverlay as={Link} to={`/events/${event.id}`}>
              {event.name}
            </LinkOverlay>
          </Heading>
          <Link to={`${ERoutes.supplier}/${event.companyId}`}>
            <Heading as="h4" size="sm" _hover={{ textDecor: "underline" }}>
              {event.companyName}
            </Heading>
          </Link>

          <HStack spacing="0.5" wrap="wrap">
            <Text fontSize="sm">{formattedDate}</Text>
          </HStack>

          <Wrap spacing="2">
            {event.regions.map((region) => (
              <Tag fontSize="xs" key={region.regionId}>
                {region.name}
              </Tag>
            ))}
          </Wrap>
        </Stack>
      </Box>
    </LinkBox>
  );
};

export default EventSimple;
