export const csvDownload = <T extends { [key: string]: any }>(data: T[], name = "export.csv", delimiter = ",") => {
  const csvHeader = Object.keys(data[0]);
  const csvBody = data.map((row) => {
    return csvHeader.map((key) => JSON.stringify(row[key] ?? "")).join(delimiter);
  });
  const csvTextFile = [csvHeader.join(delimiter), ...csvBody].join("\r\n");
  try {
    const blob = new Blob([csvTextFile], {
      type: "text/plain;charset=utf-8",
    });
    downloadBlob(blob, name);
  } catch (error) {
    console.error("Something went wrong downloading file " + error);
  }
};

export function downloadBlob(blobFile: Blob, fileName: string): void {
  const link = document.createElement("a");
  const url = URL.createObjectURL(blobFile);
  link.href = url;
  link.download = fileName;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
