export type GUID = string;
export type DateString = string;

export enum UserRoles {
  Supplier = 1,
  Member = 3,
  Moderator = 5,
  Admin = 10,
}

export enum CompanyTypes {
  Member = 1,
  Supplier,
}

export enum ModerationStatus {
  Private = 0,
  Pending = 10,
  Rejected = 20,
  Approved = 30,
  Cancelled = 90,
}

export enum InvitationStatus {
  Awaiting = 1,
  Sent = 2,
  Accepted = 3,
}

export interface IdName {
  name: string;
  id: GUID;
}
export interface NumberIdName {
  name: string;
  id: number;
}

export interface TimeZone {
  value: string;
  label: string;
  abbrev: string;
  offset: number;
  altName: string;
}

export interface IUser {
  id: string;
  username: string;
  name: string;
  userCompany: {
    companyId: string;
    name: string;
    companyType: {
      id: number;
      name: string;
    };
  };
  isDeleted: boolean;
  hasAllocatedLicence: boolean;
  role: {
    id: number;
    name: string;
  };
  invitationStatus: {
    id: number;
    name: string;
  };
  termsAccepted: boolean;
  valueTrackerAccess: boolean;
}

export interface IUserDetails extends IUser {
  favouriteCompanies: {
    companyId: GUID;
    name: string;
    companyType: NumberIdName;
  }[];
}

export interface ICompany {
  id: GUID;
  name: string;
  companyType: {
    id: number;
    name: string;
  };
}

export interface ICompanyDetail {
  id: GUID;
  name: string;
  numberOfLicences: number;
  numberOfLicencesAvailable: number;
  companyUsers: [
    {
      id: GUID;
      username: string;
      displayName: string;
      jobTitle: string;
      telephone: string;
    }
  ];
  companyCategories: [
    {
      id: GUID;
      name: string;
    }
  ];
  companyAddresses: [
    {
      id: GUID;
      address1: string;
      address2: string;
      address3: string;
      address4: string;
      townCity: string;
      countyState: string;
      postCodeZip: string;
      countryName: string;
    }
  ];
  thumbnailBase64: string;
  companyType: {
    id: number;
    name: string;
  };
}

export interface ICompanyContact {
  id: GUID;
  name: string;
  jobTitle: string;
  emailAddress: string;
  geography: string;
  telephone: string;
}

export interface ICompanyAddress {
  id: GUID;
  country: {
    id: GUID;
    name: string;
    regionId: GUID;
    regionName: string;
  };
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  countyState: string;
  townCity: string;
  postCodeZip: string;
}

export interface ICompanyUser {
  id: GUID;
  username: string;
  displayName: string;
  role: {
    id: number;
    name: string;
  };
  jobTitle: string;
  telephone: string;
  hasAllocatedLicence: boolean;
  invitationStatus: {
    id: number;
    name: string;
  };
  isDeleted: boolean;
  valueTrackerAccess: boolean;
}

export interface ICompanyProfile {
  id: string;
  content: string;
}

export interface ICompanyOccasion {
  id: GUID;
  name: string;
  companyId: GUID;
  companyName: string;
  startDate: DateString;
  timezone: string;
  regions: {
    regionId: GUID;
    name: string;
  }[];
}

export interface ICompanyDocument {
  id: GUID;
  title: string;
  description: string;
  fileName: string;
  storageLocation: string;
}

export interface ICompanyCreateBody extends Omit<ICompany, "id"> {
  numberOfLicences: number;
  thumbnailBase64: string;
}
export interface ICountry {
  id: GUID;
  name: string;
  regionId: GUID;
  regionName: string;
}

export interface IMemberSite {
  id: GUID;
  siteName: string;
  countryId: GUID;
  countryName: string;
}

export interface IProfile {
  id: string;
  approvedProfile: {
    content: string;
  };
  proposedProfile: {
    content: string;
    changeDate: DateString;
    moderationStatus: NumberIdName;
    changeOwnerComment: string;
    isDraft: boolean;
    moderatorComment: string;
    changeOwner: string;
  } | null;
}

export interface IModerationItem {
  id: GUID;
  moderationType: NumberIdName;
  moderationStatus: NumberIdName;
  moderatedEntityId: GUID;
  correlationId: number;
}
export interface IModerationQueueItem {
  id: GUID;
  moderationType: NumberIdName;
  moderationStatus: NumberIdName;
  moderatedEntityId: GUID;
  moderatorComment: string;
  changeOwnerComment: string;
  changeOwnerName: string;
  changeOwnerCompanyName: string;
  changeDate: DateString;
  changeOwnerId: string;
  friendlyName: string;
  correlationId: number;
}

export interface IOccasion {
  id: GUID;
  moderationId: GUID;
  regions: {
    id: GUID;
    name: string;
  }[];
  approvedOccasion: {
    name: string;
    companyId: GUID;
    companyName: string;
    description: string;
    startDate: DateString;
    endDate: DateString;
    timezone: string;
  };
  proposedOccasion: {
    name: string;
    description: string;
    startDate: DateString;
    endDate: DateString;
    timezone: string;
    moderationStatus: NumberIdName;
    changeOwner: string;
    changeDate: DateString;
    isDraft: boolean;
    changeOwnerComment: string;
    moderatorComment: string;
  };
}

export interface IContact {
  id: GUID;
  moderationId: GUID;
  approvedContact: {
    name: string;
    jobTitle: string;
    emailAddress: string;
    geography: string;
    telephone: string;
  };
  proposedContact: {
    name: string;
    jobTitle: string;
    emailAddress: string;
    geography: string;
    telephone: string;
    moderationStatus: {
      id: number;
      name: string;
    };
    changeOwner: string;
    changeDate: DateString;
    isDraft: boolean;
    changeOwnerComment: string;
    moderatorComment: string;
  };
}

export interface IDocumentSettings {
  maxSizeBytes: number;
  maxSizeMb: number;
  fileExtensionWhiteList: string[];
}

export interface IDocument {
  id: GUID;
  companyId: GUID;
  moderationId: GUID;
  approvedDocument: {
    title: string;
    description: string;
    fileName: string;
    storageLocation: string;
  };
  proposedDocument: {
    title: string;
    description: string;
    fileName: string;
    storageLocation: string;
    moderationStatus: NumberIdName;
    fileAntiVirusScanResult: NumberIdName;
    changeOwner: string;
    changeDate: DateString;
    isDraft: boolean;
    changeOwnerComment: string;
    moderatorComment: string;
  };
}

export interface INewsFeedItemSimple {
  id: GUID;
  companyId: GUID;
  companyName: string;
  companyType: NumberIdName;
  title: string;
  author: IdName;
  description: string;
  postDateTime: DateString;
  postThumbnail: string;
  regions: IdName[];
}

export interface INewsFeed {
  currentPage: number;
  totalItems: number;
  totalPages: number;
  items: INewsFeedItemSimple[];
}

export interface INewsFeedItemDetail {
  id: GUID;
  moderationId: GUID;
  regions: {
    id: GUID;
    name: string;
  }[];
  approvedNewsPost: {
    title: string;
    author: IdName;
    description: string;
    newsPostDate: DateString;
    thumbnail: string;
    content: string;
    companyId: GUID;
    companyName: string;
    companyType: NumberIdName;
  };
  proposedNewsPost: {
    moderationStatus: NumberIdName;
    changeOwner: string;
    changeDate: DateString;
    isDraft: boolean;
    changeOwnerComment: string;
    moderatorComment: string;
    title: string;
    author: IdName;
    description: string;
    newsPostDate: DateString;
    thumbnail: string;
    content: string;
  } | null;
}

export interface ISupplier {
  id: GUID;
  name: string;
  companyType: NumberIdName;
  isUserFavourite: boolean;
  companyCategories: IdName[];
}

export interface IUserOccasion {
  id: GUID;
  name: string;
  companyId: GUID;
  companyName: string;
  description: string;
  startDate: DateString;
  timezone: string;
  regions: IdName[];
  isFavouriteOccasion: boolean;
  isFavouriteSupplier: boolean;
}

export interface IUserOccasionFeed {
  items: IUserOccasion[];
}

export interface ISearch {
  termSearch: string;
  suppliers: {
    companyId: GUID;
    companyName: string;
  }[];
  categories: {
    categoryId: GUID;
    categoryName: string;
  }[];
  occasions: {
    occasionId: GUID;
    occassionName: string;
    companyId: GUID;
    companyName: string;
  }[];
  contacts: {
    contactId: GUID;
    contactName: string;
    companyId: GUID;
    companyName: string;
  }[];
  documents: {
    documentId: GUID;
    documentTitle: string;
    companyId: GUID;
    companyName: string;
  }[];
  newsPosts: {
    newsPostId: GUID;
    newsPostTitle: string;
    companyId: GUID;
    companyName: string;
  }[];
}

interface ICompanyBrief {
  companyId: GUID;
  name: string;
  companyType: NumberIdName;
}

export interface IUserSys {
  id: GUID;
  username: string;
  displayName: string;
  userCompany?: ICompanyBrief;
  isDeleted: boolean;
  hasAllocatedLicence?: boolean;
  role: NumberIdName;
  invitationStatus: NumberIdName;
  telephone: string;
  jobTitle: string;
  valueTrackerAccess: boolean;
}

export interface IAuthorContact {
  id: GUID;
  name: string;
  email: string;
  jobTitle: string;
  telephone: string;
}

export interface IUserSearch {
  id: GUID;
  username: string;
  name: string;
  userCompany: {
    companyId: GUID;
    name: string;
    companyType: {
      id: number;
      name: string;
    };
  };
  isDeleted: boolean;
  hasAllocatedLicence: boolean;
  role: NumberIdName;
  invitationStatus: NumberIdName;
  termsAccepted: boolean;
}

export interface InvitationStatusReport {
  totalUsers: number;
  totalAccepted: number;
  totalAwaiting: number;
  totalSent: number;
  users: {
    userId: GUID;
    username: string;
    name: string;
    jobTitle: string;
    companyId: GUID;
    companyName: string;
    companyType: string;
    invitationStatus: string;
    licenceStatus: string;
  }[];
}
