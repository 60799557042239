import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "lib/axios";
import { useTranslation } from "react-i18next";
import { GUID, IModerationItem, IModerationQueueItem, ModerationStatus } from "types";
import { useToast } from "@chakra-ui/react";
import { useErrorToast } from "hooks/useErrorToast";
import { AxiosError } from "axios";

function useModeration() {
  return useQuery("Moderation", (): Promise<IModerationItem[]> => axios.get("Moderation"));
}
function useModerationQueue() {
  return useQuery("Moderation Queue", (): Promise<IModerationQueueItem[]> => axios.get("Moderation/queue"), {
    cacheTime: 1000 * 60 * 10, // 10 minutes cache time
  });
}

export function filterDrafts(items: IModerationQueueItem[]) {
  return items
    .filter((item) => item.moderationStatus.id === ModerationStatus.Private)
    .filter((item) => item.moderationType.id !== 4);
}

export function filterPending(items: IModerationQueueItem[]) {
  return items.filter((item) => item.moderationStatus.id === ModerationStatus.Pending);
}

export function filterRejected(items: IModerationQueueItem[]) {
  return items.filter((item) => item.moderationStatus.id === ModerationStatus.Rejected);
}

function useModerationQueueAll() {
  return useQuery(["Moderation", "All"], (): Promise<IModerationQueueItem[]> => axios.get("Moderation/queue"), {
    cacheTime: 1000 * 60 * 10, // 10 minutes cache time
    refetchOnWindowFocus: false,
  });
}

function useDrafts() {
  return useQuery(["Moderation", "Drafts"], (): Promise<IModerationQueueItem[]> => axios.get("Moderation/queue"), {
    select: filterDrafts,
    cacheTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
}

function usePendingModeration() {
  return useQuery(["Moderation", "Pending"], (): Promise<IModerationQueueItem[]> => axios.get("Moderation/queue"), {
    select: filterPending,
    cacheTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
}
function useRejected() {
  return useQuery(["Moderation", "Rejected"], (): Promise<IModerationQueueItem[]> => axios.get("Moderation/queue"), {
    select: filterRejected,
    cacheTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
}

interface ApprovalBody {
  id: GUID;
  correlationId: number;
  comments?: string;
}

function useApproveModerated() {
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  const { t } = useTranslation();
  return useMutation(
    (approvalBody: ApprovalBody) => axios.post(`Moderation/${approvalBody.id}/approve`, approvalBody),
    {
      onSuccess: () => {
        toast({
          position: "bottom-left",
          status: "success",
          title: t("Approved"),
        });
        queryClient.invalidateQueries("Moderation Queue");
      },
      onError: (err: AxiosError) => {
        errorToast(err);
        if (err.response?.status === 409) {
          queryClient.invalidateQueries("Moderation Queue");
        }
      },
    }
  );
}

interface RejectionBody {
  id: GUID;
  rejectionComments: string;
  correlationId: number;
}
function useRejectModerated() {
  const toast = useToast();
  const { t } = useTranslation();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  return useMutation(
    (rejectionBody: RejectionBody) => axios.post(`Moderation/${rejectionBody.id}/reject`, rejectionBody),
    {
      onSuccess: () => {
        toast({
          position: "bottom-left",
          status: "success",
          title: t("Rejected"),
        });
        queryClient.invalidateQueries("Moderation Queue");
      },
      onError: (err: AxiosError) => {
        errorToast(err);
        if (err.response?.status === 409) {
          queryClient.invalidateQueries("Moderation Queue");
        }
      },
    }
  );
}

interface IWithdrawBody {
  entity: string;
  id: GUID;
}

function useWithdrawEntity() {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation(
    (withDrawBody: IWithdrawBody) => axios.post(`${withDrawBody.entity}/withdraw`, { id: withDrawBody.id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Moderation Queue");
        queryClient.invalidateQueries(["Moderation"]);

        toast({
          position: "bottom-left",
          status: "success",
          title: t("Withdrawn"),
        });
      },
      onError: (err: AxiosError) => {
        errorToast(err);
      },
    }
  );
}

export {
  useModerationQueue,
  useApproveModerated,
  useModeration,
  useRejectModerated,
  useWithdrawEntity,
  useDrafts,
  usePendingModeration,
  useRejected,
  useModerationQueueAll,
};
