import { Box } from "@chakra-ui/react";
import React from "react";

interface Props {
  richText?: string;
}

const RichTextRender = ({ richText }: Props) => {
  let profileMarkup = richText ?? "";
  return (
    <Box className="sun-editor" style={{ border: "none" }}>
      <Box className="se-wrapper">
        <Box
          p="2"
          fontSize="16"
          className="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"
          dangerouslySetInnerHTML={{ __html: profileMarkup }}
        ></Box>
      </Box>
    </Box>
  );
};

export default RichTextRender;
