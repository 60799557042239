import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useAcceptLegal, useRejectLegal } from "api/user";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ERoutes } from "Router/types";

interface Props {
  isOpen: boolean;
}

export const TermsModal = (props: Props) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const accept = useAcceptLegal();
  const reject = useRejectLegal();
  const history = useHistory();
  function handleReject() {
    reject.mutate();
  }

  function handleAccept() {
    accept.mutate();
  }
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure({ isOpen: props.isOpen });
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered initialFocusRef={buttonRef} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Terms And Conditions")}</ModalHeader>
        <ModalBody height="75vh">
          <iframe
            title="privacy policy"
            src="https://stintellyncsharedprod.blob.core.windows.net/legal/en/abfhub/TermsOfUse.pdf#view=FitH&toolbar=0&statusbar=0&messages=0&navpanes=0"
            style={{ width: "100%", height: "inherit" }}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={handleReject} variant="ghost">
            {t("Reject and log out")}
          </Button>
          <Button onClick={handleAccept} ref={buttonRef} colorScheme="blue" ml={3}>
            {t("Accept")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
