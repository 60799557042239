import React from "react";
import { useTranslation } from "react-i18next";
import { languages } from "locales/languages";
import { Select } from "@chakra-ui/react";
interface Props {}

export const LanguageSelect = (props: Props) => {
  const { i18n } = useTranslation();
  const [initialValue] = i18n.language.split("-");
  return (
    <Select id="language-select" size="xs" value={initialValue} onChange={(e) => i18n.changeLanguage(e.target.value)}>
      {languages.map((language) => (
        <option value={language.code} key={language.code}>
          {language.code.toUpperCase()}
        </option>
      ))}
    </Select>
  );
};
