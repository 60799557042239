import React from "react";

interface Props {
  error: any;
}

const AuthError = ({ error }: Props) => {
  console.log(error);
  return <div>An Error Occurred: check the console</div>;
};

export default AuthError;
