import { useAccount, useMsal } from "@azure/msal-react";
import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useErrorToast } from "hooks/useErrorToast";
import axios from "lib/axios";
import { cleanParamsLoop } from "lib/objectHelpers";
import QueryString from "qs";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GUID,
  ICompanyOccasion,
  ICompanyUser,
  IUser,
  IUserDetails,
  IUserOccasionFeed,
  IUserSearch,
  IUserSys,
  NumberIdName,
  UserRoles,
} from "types";

function useUserSelf() {
  return useQuery("Self", (): Promise<IUser> => axios.get("User/self"), {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 30,
    staleTime: 1000 * 60 * 30,
  });
}

interface ICreateUserBody {
  companyId: string;
  username: string;
  displayName: string;
  sendInvitationNow: boolean;
  jobTitle: string;
  telephone: string;
  role: null;
  valueTrackerAccess: boolean;
}
interface ICreateAdminBody {
  companyId: null;
  username: string;
  displayName: string;
  sendInvitationNow: boolean;
  jobTitle: string;
  telephone: string;
  role: {
    id: number;
    name: string;
  };
}

function useCreateUser() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((body: ICreateUserBody | ICreateAdminBody) => axios.post("User", body), {
    onSuccess: (res, variables) => {
      toast({
        status: "success",
        position: "bottom-left",
        title: t("Success"),
        description: t(`User Created`),
      });
      if (variables.companyId) {
        queryClient.invalidateQueries(["Company", "Users", variables.companyId]);
      }
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

interface IEditUserBody {
  id: string;
  displayName: string;
  telephone: string;
  jobTitle: string;
}

function useEditUser() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((user: IEditUserBody) => axios.put(`User/${user.id}`, user), {
    onSuccess: (res, variables) => {
      toast({
        status: "success",
        position: "bottom-left",
        title: t("Success"),
        description: t(`User Updated`),
      });
      queryClient.invalidateQueries(["Company"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

interface IEditUserCompanyBody {
  userId: GUID;
  companyId: GUID;
}

function useEditUserCompany() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((body: IEditUserCompanyBody) => axios.post(`User/changecompany`, body), {
    onSuccess: (res, variables) => {
      toast({
        status: "success",
        position: "bottom-left",
        title: t("Success"),
        description: t(`Company Changed`),
      });
      queryClient.invalidateQueries(["Users", "search"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useUserDetails(userId?: string) {
  return useQuery(["User", userId], (): Promise<IUserDetails> => axios.get(`User/${userId}`), {
    enabled: !!userId,
    // 30 min cache time to start
    cacheTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
  });
}

interface FavCompanyToggleBody {
  isFavourite: boolean;
  userId: GUID;
  companyId: GUID;
}

function useFavCompanyToggle() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation(
    (body: FavCompanyToggleBody) =>
      axios.post(`User/${body.userId}/favs/company/${body.isFavourite ? "remove" : "add"}`, body),
    {
      onSuccess: (res, variables) => {
        toast({
          status: "success",
          position: "bottom-left",
          title: t("Success"),
          description: t(`Company ${variables.isFavourite ? "Unfavourited" : "Favourited"}`),
        });
        queryClient.invalidateQueries(["User", variables.userId]);
        queryClient.invalidateQueries("Suppliers");
      },
      onError: (err: AxiosError) => {
        errorToast(err);
      },
    }
  );
}

interface FavOccasionToggleBody {
  isFavourite: boolean;
  userId: GUID;
  occasionId: GUID;
}

function useFavOccasionToggle() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation(
    (body: FavOccasionToggleBody) =>
      axios.post(`User/${body.userId}/favs/occasion/${body.isFavourite ? "remove" : "add"}`, body),
    {
      onSuccess: (res, variables) => {
        toast({
          status: "success",
          position: "bottom-left",
          title: t("Success"),
          description: t(`Event ${variables.isFavourite ? "Unfavourited" : "Favourited"}`),
        });
        queryClient.invalidateQueries(["User", variables.userId]);
        queryClient.invalidateQueries(["User", variables.userId, "Occasions"]);
        queryClient.invalidateQueries("OccasionFeed");
      },
      onError: (err: AxiosError) => {
        errorToast(err);
      },
    }
  );
}

function useDeleteUser() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  return useMutation((userId: ICompanyUser["id"]) => axios.delete(`User/${userId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["Company", "Users"]);
      queryClient.invalidateQueries(["System Users"]);
      queryClient.invalidateQueries(["Users", "search"]);
      toast({
        position: "bottom-left",
        status: "success",
        title: t("Success"),
        description: t("User has been deleted"),
      });
    },
    onError: () => {
      toast({
        position: "bottom-left",
        status: "error",
        title: t("error"),
        description: t("Something went wrong, please try again"),
      });
    },
  });
}

function useUserLicenceAllocation() {
  const { t } = useTranslation();
  const toast = useToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  return useMutation(
    (body: { userId: string; hasAllocatedLicence: boolean }) =>
      axios.post(`User/${body.userId}/licence/${body.hasAllocatedLicence ? "allocate" : "deallocate"}`),
    {
      onSuccess: (res, variables) => {
        toast({
          status: "success",
          position: "bottom-left",
          title: t("Success"),
          description: t(`User licence ${variables.hasAllocatedLicence ? "allocated" : "deallocated"}`),
        });
        queryClient.invalidateQueries(["Company"]);
      },
      onError: (err: AxiosError) => {
        errorToast(err);
      },
    }
  );
}

export interface IOccasionFeedFilters {
  favouriteOccasionsOnly: boolean;
  favouriteSuppliersOnly: boolean;
  regions?: string[];
  startDateFrom: Date;
  startDateTo: Date;
  companyId?: string;
}
function useUserOccasionFeed(filters: IOccasionFeedFilters) {
  let cleanParams = cleanParamsLoop(filters);
  return useQuery(
    [
      "OccasionFeed",
      {
        ...filters,
        startDateFrom: filters.startDateFrom.toDateString(),
        startDateTo: filters.startDateTo.toDateString(),
      },
    ],
    (): Promise<IUserOccasionFeed> =>
      axios.get("/User/occasionfeed", {
        params: cleanParams,
        paramsSerializer: (params) =>
          QueryString.stringify(params, { arrayFormat: "repeat", serializeDate: (d) => d.toJSON() }),
      }),
    {
      staleTime: 1000 * 60 * 5,
    }
  );
}

interface UserFavouiteParams {
  startDateFrom?: string;
  startDateTo?: string;
}

function useUserFavouriteOccasions(userId?: GUID, options?: UserFavouiteParams) {
  return useQuery(
    ["User", userId, "Occasions"],
    (): Promise<ICompanyOccasion[]> => {
      return axios.get(`/User/${userId}/occasions`, {
        params: options,
      });
    },
    {
      enabled: !!userId,
    }
  );
}

function useIsUserFavouriteOccasion(occasionId: GUID): boolean {
  const { data } = useUserSelf();
  const { data: favouriteEvents } = useUserFavouriteOccasions(data?.id);
  return favouriteEvents?.map((event) => event.id).includes(occasionId) ?? false;
}

function useIsFavouriteCompany(companyId: GUID): boolean {
  const { data } = useUserSelf();
  const { data: userDetails } = useUserDetails(data?.id);
  return userDetails?.favouriteCompanies.map((company) => company.companyId).includes(companyId) ?? false;
}

function useUserSys() {
  return useQuery(["System Users"], (): Promise<IUserSys[]> => axios.get("/User/sys"), {});
}
function useCompanyUsersDetail(companyId: string) {
  return useQuery(
    ["Company", "Users", companyId],
    (): Promise<ICompanyUser[]> => axios.get(`/User/company/${companyId}`),
    {
      enabled: !!companyId,
    }
  );
}
interface SystemUserPostBody {
  username: string;
  displayName: string;
  sendInvitationNow: boolean;
  telephone: string;
  role: NumberIdName;
}
function useCreateSystemUser() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((body: SystemUserPostBody) => axios.post("/User/", body), {
    onSuccess: () => {
      toast({
        status: "success",
        position: "bottom-left",
        title: t("Success"),
        description: t(`User created`),
      });
      queryClient.invalidateQueries(["System Users"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useAcceptLegal() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation(() => axios.post("/User/legal/accept"), {
    onSuccess: () => {
      toast({
        status: "success",
        position: "bottom-left",
        title: t("Success"),
        description: t(`Accepted Terms`),
      });
      queryClient.invalidateQueries("Self");
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}
function useRejectLegal() {
  const { t } = useTranslation();
  const toast = useToast();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const logout = async () => {
    if (account?.homeAccountId) {
      const currentAccount = instance.getAccountByHomeId(account?.homeAccountId);
      await instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: "/",
      });
    }
  };
  const errorToast = useErrorToast();

  return useMutation(() => axios.post("/User/legal/reject"), {
    onSuccess: () => {
      toast({
        status: "success",
        position: "bottom-left",
        title: t("Success"),
        description: t(`Rejected Terms`),
      });
      logout();
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useSendInvite() {
  const toast = useToast();
  const { t } = useTranslation();
  const errorToast = useErrorToast();
  return useMutation((userId: string) => axios.post(`User/${userId}/invite/send`), {
    onSuccess: () => {
      toast({
        status: "success",
        position: "bottom-left",
        title: t("Success"),
        description: t(`Invite Sent`),
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useUserSearch(searchTerm: string) {
  return useQuery(
    ["Users", "search", searchTerm],
    (): Promise<IUserSearch[]> =>
      axios.get("User/search", {
        params: {
          term: searchTerm,
        },
      }),
    {
      enabled: searchTerm.length > 2,
      keepPreviousData: true,
    }
  );
}

export {
  useUserSelf,
  useUserDetails,
  useFavCompanyToggle,
  useCreateUser,
  useEditUser,
  useDeleteUser,
  useUserLicenceAllocation,
  useFavOccasionToggle,
  useUserOccasionFeed,
  useUserSys,
  useCreateSystemUser,
  useUserFavouriteOccasions,
  useIsUserFavouriteOccasion,
  useAcceptLegal,
  useRejectLegal,
  useCompanyUsersDetail,
  useSendInvite,
  useIsFavouriteCompany,
  useUserSearch,
  useEditUserCompany,
};
