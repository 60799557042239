import { StarIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { useFavOccasionToggle, useUserSelf } from "api/user";
import React from "react";

interface Props {
  occasionId: string;
  isFavourite: boolean;
  iconSize?: string;
}

const FavouriteOccasionToggle = ({ occasionId, isFavourite, iconSize = "16px" }: Props) => {
  const favouriteOccasionToggle = useFavOccasionToggle();

  const user = useUserSelf();

  const iconColor = isFavourite ? "yellow.300" : "gray.400";

  function handleClick() {
    if (user.isSuccess) {
      favouriteOccasionToggle.mutate({ isFavourite, occasionId, userId: user.data.id });
    }
  }
  return (
    <IconButton
      aria-label="favourite-toggle"
      onClick={handleClick}
      variant="ghost"
      icon={<StarIcon boxSize={iconSize} color={iconColor} />}
    />
  );
};

export { FavouriteOccasionToggle };
