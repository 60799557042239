import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Table, Thead, Tr, Th, Tbody, Td, IconButton, Button, ButtonGroup } from "@chakra-ui/react";
import { useDeleteDocument, useDownloadDocument } from "api/document";
import { ConfirmDeleteModal } from "components/ConfirmDeleteModal";
import { useIsOwner } from "hooks/useIsOwner";
import React from "react";
import { useTranslation } from "react-i18next";
import { GUID, ICompanyDocument } from "types";
import EditDocumentForm from "../../../components/Entitites/document/EditDocumentModal";

interface Props {
  data: ICompanyDocument[];
  companyId: GUID;
}

function DocumentsTable({ data, companyId }: Props) {
  const deleteDocument = useDeleteDocument();
  const [documentToEdit, setDocumentToEdit] = React.useState<ICompanyDocument | null>(null);
  const [docuemntToDelete, setDocumentToDelete] = React.useState<string | null>(null);
  const download = useDownloadDocument();
  const isOwner = useIsOwner(companyId);
  const { t } = useTranslation();
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th width="33%">{t("Title")}</Th>
            <Th>{t("Description")}</Th>
            {isOwner ? <Th /> : null}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((document) => (
            <Tr key={document.id}>
              <Td>
                <Button
                  whiteSpace="break-spaces"
                  textAlign="start"
                  variant="link"
                  onClick={() => download.mutate({ documentId: document.id, fileName: document.fileName })}
                >
                  {document.title}
                </Button>
              </Td>
              <Td>{document.description}</Td>
              {isOwner ? (
                <>
                  <Td isNumeric>
                    <ButtonGroup spacing="4">
                      <IconButton aria-label={`edit`} variant="ghost" onClick={() => setDocumentToEdit(document)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" variant="ghost" onClick={() => setDocumentToDelete(document.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ButtonGroup>
                  </Td>
                </>
              ) : null}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ConfirmDeleteModal
        entity="Document"
        deleteKey={docuemntToDelete}
        handleClose={() => setDocumentToDelete(null)}
        deleteFunction={deleteDocument}
      />
      {documentToEdit ? (
        <EditDocumentForm
          open={!!documentToEdit}
          document={documentToEdit}
          handleClose={() => setDocumentToEdit(null)}
        />
      ) : null}
    </>
  );
}

export { DocumentsTable };
