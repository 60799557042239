import { Box } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

interface Props {}

export const FullPageSplash = (props: PropsWithChildren<Props>) => {
  return (
    <Box w="100vw" h="100vh" display="flex" alignItems="center" bg="gray.200">
      {props.children}
    </Box>
  );
};
