import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "lib/axios";
import { DateString, GUID, IOccasion } from "types";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useErrorToast } from "hooks/useErrorToast";

interface IOccasionPostBody {
  companyId: GUID;
  name: string;
  description: string;
  timezone: string;
  isDraft: boolean;
  startDate: DateString | Date;
  endDate: DateString | Date;
  regions: GUID[];
}
function useCreateOccasion() {
  const toast = useToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation((occasion: IOccasionPostBody) => axios.post("Occasion", occasion), {
    onSuccess: () => {
      toast({
        title: t("Event Created"),
        description: t("The Event has been created."),
        status: "success",
        isClosable: true,
        position: "bottom-left",
      });
      queryClient.invalidateQueries(["Occasions"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useOccasion(occasionId?: string) {
  return useQuery(["Occasion", occasionId], (): Promise<IOccasion> => axios.get(`Occasion/${occasionId}`), {
    enabled: !!occasionId,
  });
}

interface OccasionRegionEditBody {
  occasionId: GUID;
  regionId: GUID;
}

function useAddOccasionRegion() {
  const queryClient = useQueryClient();
  return useMutation((body: OccasionRegionEditBody) => axios.post(`Occasion/${body.occasionId}/regions/add`, body), {
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries(["Occasion", variables.occasionId]);
    },
  });
}
function useRemoveOccasionRegion() {
  const queryClient = useQueryClient();
  return useMutation((body: OccasionRegionEditBody) => axios.post(`Occasion/${body.occasionId}/regions/remove`, body), {
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries(["Occasion", variables.occasionId]);
    },
  });
}

interface IEditOccasionBody {
  id: GUID;
  name: string;
  description: string;
  descriptionChanged: boolean;
  timezone: string;
  startDate: DateString;
  endDate: DateString;
  isDraft: boolean;
  comment: string;
}

function useEditOccasion() {
  const toast = useToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation((occasion: IEditOccasionBody) => axios.put(`Occasion/${occasion.id}`, occasion), {
    onSuccess: (res, variables) => {
      toast({
        title: t("Document Edited"),
        description: t("The Event has been edited."),
        status: "success",
        isClosable: true,
        position: "bottom-left",
      });
      queryClient.invalidateQueries(["Occasions"]);
      queryClient.invalidateQueries(["Occasions", variables.id]);
      queryClient.invalidateQueries(["Moderation"]);
      queryClient.invalidateQueries("Moderation Queue");
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useDeleteOccasion() {
  const toast = useToast();
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation((occasionId: GUID) => axios.delete(`Occasion/${occasionId}`), {
    onSuccess: () => {
      toast({
        title: t("Event Deleted"),
        description: t("The Event has been deleted."),
        status: "success",
        isClosable: true,
        position: "bottom-left",
      });
      queryClient.invalidateQueries(["Occasions"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

export {
  useCreateOccasion,
  useOccasion,
  useEditOccasion,
  useDeleteOccasion,
  useAddOccasionRegion,
  useRemoveOccasionRegion,
};
