import { Box, Button } from "@chakra-ui/react";
import { useCompanyProfile } from "api/company";
import { LoadingError } from "components/LoadingError";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useIsOwner } from "hooks/useIsOwner";
import React from "react";
import { useTranslation } from "react-i18next";
import { GUID } from "types";
import { DisplayProfile } from "./DisplayProfile";
import { EditProfile } from "../../../components/Entitites/profile/EditProfile";

interface Props {
  companyId: GUID;
}

const Profile = ({ companyId }: Props) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const { t } = useTranslation();
  const QProfile = useCompanyProfile(companyId);
  const isProfileOwner = useIsOwner(companyId);
  return (
    <Box id="supplier-profile-wrapper">
      {isProfileOwner ? (
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={() => setIsEditing((s) => !s)} justifySelf="flex-end">
            {isEditing ? t("Cancel") : t("Edit Profile")}
          </Button>
        </Box>
      ) : null}
      {QProfile.isLoading || QProfile.isIdle ? (
        <LoadingSpinner />
      ) : QProfile.isError ? (
        <LoadingError />
      ) : isEditing ? (
        <EditProfile profileId={QProfile.data?.id} />
      ) : (
        <DisplayProfile profile={QProfile.data} />
      )}
    </Box>
  );
};

export default Profile;
