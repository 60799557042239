import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  HStack,
  HTMLChakraProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import React from "react";
import { useTranslation } from "react-i18next";
import { useSearch } from "api/search";
import { LoadingError } from "components/LoadingError";
import { Occasions } from "./Occasions";
import { Contacts } from "./Contacts";
import { Suppliers } from "./Suppliers";
import { useLocation } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import { Documents } from "./Documents";
import { Posts } from "./Posts";

function Search() {
  const { t } = useTranslation();
  const [query, setQuery] = React.useState("");
  const menu = useDisclosure();
  const modal = useDisclosure({ onClose: () => setQuery("") });
  const menuRef = React.useRef<HTMLDivElement>(null);
  const searchTerm = useDebounce(query, 300);
  const QSearchResults = useSearch(searchTerm);
  let location = useLocation();
  const { onClose } = modal;

  React.useEffect(() => {
    onClose();
  }, [location]);

  return (
    <>
      <SearchButton onClick={modal.onOpen}>{t("Search")}</SearchButton>
      <Modal scrollBehavior="inside" isOpen={modal.isOpen} onClose={modal.onClose}>
        <ModalOverlay />
        <ModalContent
          role="combobox"
          aria-expanded="true"
          aria-haspopup="listbox"
          rounded="lg"
          overflow="hidden"
          top="4vh"
          bg="transparent"
          shadow="lg"
          maxW="600px"
        >
          <Flex pos="relative" align="stretch">
            <chakra.input
              aria-autocomplete="list"
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              maxLength={64}
              sx={{
                w: "100%",
                h: "68px",
                pl: "68px",
                fontWeight: "medium",
                outline: 0,
                bg: "white",
              }}
              placeholder={t("Search")}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                menu.onOpen();
              }}
            />
            <Center pos="absolute" left={7} h="68px">
              <SearchIcon boxSize="20px" />
            </Center>
          </Flex>
          <ModalBody maxH="66vh" p="0" ref={menuRef}>
            {query.length > 0 && !QSearchResults.isIdle && (
              <Box
                sx={{
                  px: 4,
                  bg: "white",
                  ".chakra-ui-dark &": { bg: "gray.700" },
                }}
              >
                {QSearchResults.isLoading ? (
                  <Stack py="2" spacing="4">
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                  </Stack>
                ) : QSearchResults.isError ? (
                  <LoadingError />
                ) : (
                  <Box as="ul" role="listbox" borderTopWidth="1px" pt={2} pb={4}>
                    <Occasions mb="3" occasions={QSearchResults.data?.occasions} />
                    <Contacts mb="3" contacts={QSearchResults.data.contacts} />
                    <Suppliers mb="3" suppliers={QSearchResults.data.suppliers} />
                    <Documents mb="3" documents={QSearchResults.data.documents} />
                    <Posts mb="3" posts={QSearchResults.data.newsPosts} />
                  </Box>
                )}
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export { Search };

export const SearchButton = React.forwardRef(function SearchButton(
  props: HTMLChakraProps<"button">,
  ref: React.Ref<HTMLButtonElement>
) {
  const { t } = useTranslation();
  return (
    <Button
      flex="1"
      type="button"
      role="search"
      mx="6"
      ref={ref}
      lineHeight="1.2"
      w="100%"
      whiteSpace="nowrap"
      display={{ base: "none", sm: "flex" }}
      alignItems="center"
      color="gray.400"
      py="3"
      px="4"
      outline="0"
      _focus={{ shadow: "outline" }}
      shadow="base"
      rounded="md"
      aria-label="Search"
      {...props}
    >
      <SearchIcon />
      <HStack w="full" ml="3" spacing="4px">
        <Text textAlign="left" flex="1">
          {t("Search")}
        </Text>
      </HStack>
    </Button>
  );
});
