import { StarIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { useFavCompanyToggle, useUserSelf } from "api/user";
import React from "react";

interface Props {
  companyId: string;
  isFavourite: boolean;
}

const FavouriteCompanyToggle = ({ companyId, isFavourite }: Props) => {
  const favCompanyToggle = useFavCompanyToggle();
  const user = useUserSelf();

  const iconColor = isFavourite ? "yellow.300" : "gray.400";

  function handleClick() {
    if (user.isSuccess) {
      favCompanyToggle.mutate({ isFavourite, companyId, userId: user.data.id });
    }
  }
  return (
    <IconButton
      aria-label="favourite-toggle"
      onClick={handleClick}
      variant="ghost"
      icon={<StarIcon boxSize="2em" color={iconColor} />}
    />
  );
};

export default FavouriteCompanyToggle;
