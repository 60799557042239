import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useDocument, useEditDocument } from "api/document";
import { EditEntityMeta } from "components/EditEntityMeta";
import { useEditButtonText } from "lib/editButtonText";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  document: Omit<FormTypes, "comment">;
  open: boolean;
  handleClose: () => void;
}

interface FormTypes {
  id: string;
  title: string;
  description: string;
  comment: string;
}

const EditDocumentForm = ({ document, open, handleClose }: Props) => {
  const { t } = useTranslation();

  const QDocument = useDocument(document.id);
  const editDocument = useEditDocument();
  const { isOpen, onClose } = useDisclosure({ isOpen: open, onClose: handleClose });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: {
      id: document.id,
      title: document.title,
      description: document.description,
      comment: "",
    },
  });

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);
  const buttonText = useEditButtonText(QDocument.data?.proposedDocument?.moderationStatus?.id);
  React.useEffect(() => {
    if (QDocument.data && QDocument.data.proposedDocument) {
      reset({
        id: QDocument.data.id,
        title: QDocument.data.proposedDocument.title,
        description: QDocument.data.proposedDocument.description,
        comment: "",
      });
    }
  }, [QDocument.data, reset]);
  function onSubmit(data: FormTypes) {
    editDocument.mutate(
      { ...data },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  }

  return (
    <>
      <Modal isCentered isOpen={isOpen} size="lg" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Edit Document")}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <EditEntityMeta meta={QDocument.data?.proposedDocument} />
              <VStack width="full" spacing="4">
                <FormControl isInvalid={!!errors.title}>
                  <FormLabel htmlFor="title">{t("Title")}</FormLabel>
                  <Input {...register("title", { required: "Name is required" })} id="title" />
                  <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.description}>
                  <FormLabel htmlFor="description">{t("Description")}</FormLabel>
                  <Input {...register("description")} id="description" />
                  <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.comment}>
                  <FormLabel htmlFor="comment">{t("Comment")}</FormLabel>
                  <Input type="comment" {...register("comment")} id="comment" />
                  <FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                {buttonText.saveDraftText}
              </Button>
              <Button type="submit" colorScheme="blue" disabled={editDocument.isLoading}>
                {buttonText.submitText}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditDocumentForm;
