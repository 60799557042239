import { Box, Button, FormControl, FormLabel, Spinner, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import { useCompanyOccasions } from "api/company";
import { DateRange } from "components/DatePicker";
import { DefaultGrid } from "components/DefaultGrid";
import { AddEvent } from "components/Entitites/event/AddEvent/AddEvent";
import EventSimple from "components/Entitites/event/EventSimple";
import { LoadingError } from "components/LoadingError";
import { addDays, subDays } from "date-fns/esm";
import { useIsOwner } from "hooks/useIsOwner";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  companyId: string;
}

interface Filters {
  startDateFrom: Date;
  startDateTo: Date;
  companyId: string;
}

const SupplierEventsScreen = ({ companyId }: Props) => {
  const [filters, setFilters] = React.useState<Filters>({
    startDateFrom: new Date(),
    startDateTo: addDays(new Date(), 45),
    companyId,
  });
  function handleDateChange(dates: { startDate: Date; endDate: Date }) {
    const { startDate, endDate } = dates;
    setFilters((prevState) => ({ ...prevState, startDateFrom: startDate, startDateTo: endDate }));
  }
  const [isNew, setIsNew] = React.useState(false);
  const { t } = useTranslation();
  const QEvents = useCompanyOccasions(companyId, filters);
  const isOwner = useIsOwner(companyId);
  return (
    <div id="supplier-events-wrapper">
      {isOwner ? (
        <Stack>
          {isNew ? (
            <Button alignSelf="flex-end" onClick={() => setIsNew(false)}>
              {t("Cancel")}
            </Button>
          ) : (
            <Button alignSelf="flex-end" onClick={() => setIsNew(true)}>
              {t("Add Event")}
            </Button>
          )}
        </Stack>
      ) : null}
      <Box display="flex" justifyContent="flex-end">
        <Box>
          <FormControl minW="240px">
            <FormLabel htmlFor="startDateFrom" mb="0">
              {t("Starting Date Range")}
            </FormLabel>
            <DateRange
              dateRange={{ startDate: filters.startDateFrom, endDate: filters.startDateTo }}
              setDateRange={handleDateChange}
            />
          </FormControl>
        </Box>
      </Box>

      {isNew ? (
        <AddEvent />
      ) : (
        <Box mt="4">
          {QEvents.isLoading ? (
            <Spinner />
          ) : !QEvents.isSuccess ? (
            <LoadingError />
          ) : (
            <DefaultGrid>
              {QEvents.data.map((event) => (
                <EventSimple key={event.id} event={event} />
              ))}
            </DefaultGrid>
          )}
        </Box>
      )}
    </div>
  );
};

export { SupplierEventsScreen };
