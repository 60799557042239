import React from "react";
import { Heading } from "@chakra-ui/react";
interface Props {
  children: React.ReactNode;
}

const SearchHeading = ({ children }: Props) => {
  return (
    <Heading size="md" as="h4">
      {children}
    </Heading>
  );
};

export { SearchHeading };
