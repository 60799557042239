import { Box, Button, SimpleGrid } from "@chakra-ui/react";
import { ICompanyNewsFeedQueryParams, useCompanyNewsfeed } from "api/company";
import { DefaultGrid } from "components/DefaultGrid";
import { LoadingError } from "components/LoadingError";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PaginationButtons } from "components/PaginationButtons";
import { useIsOwner } from "hooks/useIsOwner";
import React from "react";
import { useTranslation } from "react-i18next";
import { AddNewsPost } from "../../../components/Entitites/post/AddPost/AddPost";
import { NewsPostSimple } from "./NewsPostSimple";

interface Props {
  companyId: string;
}

const CompanyNewsfeed = ({ companyId }: Props) => {
  const [isCreatingNew, setIsCreatingNew] = React.useState(false);
  const [filters, setFilters] = React.useState<ICompanyNewsFeedQueryParams>({
    pageNumber: 1,
    pageSize: 12,
    sortOrder: "DESC",
  });
  const { t } = useTranslation();
  const QCompanyPosts = useCompanyNewsfeed(companyId, filters);
  const isOwner = useIsOwner(companyId);
  const handlePageChange = (type: "forward" | "back") => {
    setFilters((prevState) => ({
      ...prevState,
      pageNumber: type === "forward" ? prevState.pageNumber + 1 : prevState.pageNumber - 1,
    }));
  };
  return (
    <Box id="supplier-news-wrapper">
      {isOwner ? (
        <Box justifyContent="flex-end" display="flex">
          {isCreatingNew ? (
            <Button onClick={() => setIsCreatingNew(false)}>{t("Cancel")}</Button>
          ) : (
            <Button onClick={() => setIsCreatingNew(true)}>{t("Add Post")}</Button>
          )}
        </Box>
      ) : null}
      {isCreatingNew ? (
        <AddNewsPost handleClose={() => setIsCreatingNew(false)} companyId={companyId} />
      ) : (
        <>
          {QCompanyPosts.isLoading ? (
            <LoadingSpinner />
          ) : !QCompanyPosts.isSuccess ? (
            <LoadingError />
          ) : (
            <>
              <DefaultGrid>
                {QCompanyPosts.data.items?.map((item) => (
                  <NewsPostSimple post={item} key={item.id} />
                ))}
              </DefaultGrid>
              <PaginationButtons
                currentPage={QCompanyPosts.data.currentPage}
                totalPages={QCompanyPosts.data.totalPages}
                handlePageChange={handlePageChange}
              />
            </>
          )}
        </>
      )}
      {/* <AddPostModal open={isCreatingNew} companyId={companyId} handleClose={() => setIsCreatingNew(false)} /> */}
    </Box>
  );
};

export { CompanyNewsfeed };
