export function cleanParams<Params extends { [key: string]: any }>(obj: Params): Partial<Params> {
  const filteredArrayOfArrays = Object.entries(obj).filter(([_key, value]) => value != null);
  return Object.fromEntries(filteredArrayOfArrays) as Partial<Params>;
}

export function cleanParamsLoop<Params extends { [key: string]: any }>(obj: Params): Partial<Params> {
  let spread = { ...obj };
  for (let key in spread) {
    if (spread[key] == null) {
      delete spread[key];
    }
  }
  return spread;
}
// function cleanParamsLoopAdd<Params>(obj: Params): Partial<Params> {
//   let spread: Partial<Params> = {};
//   for (let key in obj) {
//     if (obj[key] != null) {
//       spread[key] = obj[key];
//     }
//   }
//   return spread;
// }
