import { DateString } from "types";
import { format } from "date-fns";

export function formatDate(dateString?: DateString, showTime = false): string {
  if (!dateString) {
    return "N/A";
  }
  if (showTime) {
    return format(new Date(dateString), "dd/MM/yyyy H:mm");
  }
  return format(new Date(dateString), "dd/MM/yyyy");
}
