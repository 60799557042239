import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { LoadingSpinner } from "components/LoadingSpinner";
import React, { ReactNode, Suspense } from "react";
import { useHistory } from "react-router-dom";
import LandingPage from "./LandingPage";
import { msalConfig } from "./utils/config";
import { CustomNavigationClient } from "./utils/NavigationClient";

const pca = new PublicClientApplication(msalConfig);

interface Props {
  children: ReactNode;
}

const Auth = ({ children }: Props) => {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Suspense fallback={<LoadingSpinner />}>
        <LandingPage>{children}</LandingPage>
      </Suspense>
    </MsalProvider>
  );
};

export default Auth;
