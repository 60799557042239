import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { format, addDays } from "date-fns";
import { addMonths } from "date-fns/esm";
import React from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useTranslation } from "react-i18next";

interface DatePickerProps {
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  setDateRange: (dates: { startDate: Date; endDate: Date }) => void;
  defaultDate?: string;
  disabled?: boolean;
  previous?: number;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DateRange = ({ dateRange, setDateRange, ...props }: DatePickerProps) => {
  const [dates, setDates] = React.useState([
    {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      key: "selection",
    },
  ]);
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  function handleCancel() {
    onClose();
    setDates([
      {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        key: "selection",
      },
    ]);
  }

  function handleConfirm() {
    setDateRange({
      startDate: dates[0].startDate,
      endDate: dates[0].endDate,
    });
    onClose();
  }
  return (
    <Box>
      <Button onClick={onOpen} rightIcon={<CalendarIcon />}>
        {format(dateRange.startDate, "dd/MM/yyyy")} - {format(dateRange.endDate, "dd/MM/yyyy")}
      </Button>
      <Modal size="5xl" onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Select Date Range")}</ModalHeader>
          <ModalBody>
            <DateRangePicker
              staticRanges={[
                // @ts-ignore
                {
                  label: t("Today"),
                  range: () => ({
                    startDate: new Date(),
                    endDate: new Date(),
                  }),
                  isSelected: () => false,
                },
                {
                  label: t("This Week"),
                  range: () => ({
                    startDate: new Date(),
                    endDate: addDays(new Date(), 7),
                  }),
                  isSelected: () => false,
                },
                {
                  label: t("The Next Month"),
                  range: () => ({
                    startDate: new Date(),
                    endDate: addMonths(new Date(), 1),
                  }),
                  isSelected: () => false,
                },
                {
                  label: t("Next Sixty Days"),
                  range: () => ({
                    startDate: new Date(),
                    endDate: addDays(new Date(), 60),
                  }),
                  isSelected: () => false,
                },
              ]}
              months={2}
              maxDate={addDays(dates[0].startDate, 60)}
              ranges={dates}
              direction="horizontal"
              //@ts-ignore
              onChange={(item) => setDates([item.selection])}
            />
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleCancel} colorScheme="blue" variant="outline">
              {t("Cancel")}
            </Button>
            <Button onClick={handleConfirm} colorScheme="blue" ml="2">
              {t("Submit")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export { DateRange };
