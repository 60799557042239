export enum ERoutes {
  root = "/",
  logout = "/logout",
  suppliers = "/suppliers",
  supplier = "/supplier",
  events = "/events",
  SearchUsers = "/admin/search-users",
  addCompany = "/admin/add-company",
  editCompanies = "/admin/edit-company",
  categoryManagement = "/admin/category-management",
  countryManagement = "/admin/country-management",
  regionManagement = "/admin/region-management",
  memberSiteManagement = "/admin/member-sites",
  moderation = "/moderation-queue",
  pending = "/pending",
  drafts = "/drafts",
  rejected = "/rejected",
  newsfeed = "/newsfeed",
  admin = "/admin",
  adminUserManagement = "/admin/user-management",
  privacyPolicy = "/privacy-policy",
  terms = "/terms-and-conditions",
  invitationStatus = "/admin/invitation-status",
  allCompanyValueTracker = "/value-tracker",
}
