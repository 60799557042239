import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "lib/axios";
import { GUID, IProfile } from "types";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useErrorToast } from "hooks/useErrorToast";
import { AxiosError } from "axios";

function useProfile(profileId?: string) {
  return useQuery(["profile", profileId], (): Promise<IProfile> => axios.get(`Profile/${profileId}`), {
    enabled: !!profileId,
  });
}

interface CreateProfileBody {
  companyId: GUID;
  content: string;
  isDraft: boolean;
}

function useCreateProfile() {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((profile: CreateProfileBody) => axios.post("Profile", profile), {
    onSuccess: (res, variables) => {
      toast({
        status: "success",
        title: t("Success"),
      });
      queryClient.invalidateQueries(["Profile", "Company", variables.companyId]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

interface EditProfileBody extends Omit<CreateProfileBody, "companyId"> {
  id: GUID;
}

function useEditProfile() {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((profile: EditProfileBody) => axios.put(`Profile/${profile.id}`, profile), {
    onSuccess: (res, variables) => {
      toast({
        status: "success",
        title: t("Success"),
      });
      queryClient.invalidateQueries(["profile", variables.id]);
      queryClient.invalidateQueries(["Moderation"]);
      queryClient.invalidateQueries("Moderation Queue");
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

export { useProfile, useCreateProfile, useEditProfile };
