import { Box, HStack, Text, Wrap } from "@chakra-ui/react";
import { formatDate } from "lib/dateHelpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { DateString, ModerationStatus, NumberIdName } from "types";
import { TextWithLabel } from "./TextWithLabel";

interface Props {
  meta?: {
    moderationStatus: NumberIdName;
    changeOwner: string;
    changeDate: DateString;
    changeOwnerComment: string;
    moderatorComment: string;
  } | null;
}

export const EditEntityMeta = ({ meta }: Props) => {
  const { t } = useTranslation();
  if (!meta) return null;
  return (
    <HStack spacing="4" justifyContent="space-between" mb="3">
      <TextWithLabel label={t("Moderation Status")} text={meta.moderationStatus.name} />
      {meta.moderationStatus.id === ModerationStatus.Rejected ? (
        <TextWithLabel label={t("Rejected Reason")} text={meta.moderatorComment} />
      ) : null}
      {meta.changeOwnerComment ? (
        <TextWithLabel label={t("Reasons for change")} text={meta.changeOwnerComment} />
      ) : null}
      <TextWithLabel label={t("Changes made by")} text={meta.changeOwner} />
      <TextWithLabel label={t("Change Date")} text={formatDate(meta.changeDate)} />
    </HStack>
  );
};
