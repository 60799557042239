import { useMutation, useQueryClient, useQuery } from "react-query";
import axios from "lib/axios";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ICompanyContact, IContact } from "types";
import { AxiosError } from "axios";
import { useErrorToast } from "hooks/useErrorToast";

function useCreateContact() {
  const { t } = useTranslation();
  const toast = useToast({});
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((contact: CreateContactPost) => axios.post("Contact", contact), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Company", variables.companyId, "contacts"]);
      toast({
        status: "success",
        title: t("Success"),
        description: t("Contact Created"),
        position: "bottom-left",
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useEditContact() {
  const { t } = useTranslation();
  const toast = useToast({});
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  return useMutation((contact: EditContactPut) => axios.put(`Contact/${contact.id}`, contact), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Contacts"]);
      queryClient.invalidateQueries(["Contact", variables.id]);
      queryClient.invalidateQueries(["Moderation"]);
      queryClient.invalidateQueries("Moderation Queue");
      toast({
        status: "success",
        title: t("Contact Edited"),
        position: "bottom-left",
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useDeleteContact() {
  const { t } = useTranslation();
  const toast = useToast({});
  const errorToast = useErrorToast();
  const queryClient = useQueryClient();
  return useMutation((contactId: ICompanyContact["id"]) => axios.delete(`Contact/${contactId}`), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["Company", "Contacts"]);
      toast({
        status: "success",
        title: t("Contact Deleted"),
        position: "bottom-left",
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useContact(contactId?: string) {
  return useQuery(["Contact", contactId], (): Promise<IContact> => axios.get(`Contact/${contactId}`), {
    enabled: !!contactId,
  });
}

export { useCreateContact, useEditContact, useDeleteContact, useContact };

interface CreateContactPost extends Omit<ICompanyContact, "id"> {
  companyId: string;
  isDraft: boolean;
}

interface EditContactPut extends ICompanyContact {
  comment?: string;
  isDraft: boolean;
}
