import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/toast";
import axios from "lib/axios";
import {
  GUID,
  ICompany,
  ICompanyAddress,
  ICompanyContact,
  ICompanyCreateBody,
  ICompanyDetail,
  ICompanyDocument,
  ICompanyOccasion,
  ICompanyProfile,
  ICompanyUser,
  IdName,
  INewsFeed,
  ISupplier,
} from "types";
import { AxiosError } from "axios";
import { cleanParamsLoop } from "lib/objectHelpers";
import qs from "qs";
import QueryString from "qs";
import { useErrorToast } from "hooks/useErrorToast";

function useCreateCompany() {
  const { t } = useTranslation();
  const toast = useToast();
  const errorToast = useErrorToast();
  return useMutation((company: ICompanyCreateBody) => axios.post(`Company`, company), {
    onSuccess: () => {
      toast({
        status: "success",
        title: t("Success"),
        description: t("Company Created"),
        isClosable: true,
        position: "bottom-left",
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useCompanyTypes() {
  return useQuery(["Company", "types"], (): Promise<IdName[]> => axios.get("Company/types"));
}

function useCompanies(companyType = "") {
  return useQuery(
    ["Companies", "companyType", companyType],
    (): Promise<ICompany[]> =>
      axios.get("Company", {
        params: !!companyType ? { companyType: Number(companyType) } : undefined,
      })
  );
}

function useCompany(companyId?: string) {
  return useQuery(["Company", companyId], (): Promise<ICompanyDetail> => axios.get(`Company/${companyId}`), {
    enabled: !!companyId,
  });
}

function useCompanyThumbnail(companyId?: string) {
  return useQuery(
    ["Company", "thumbnail", companyId],
    (): Promise<string> => axios.get(`Company/${companyId}/thumbnail`),
    {
      enabled: !!companyId,
    }
  );
}

function useCompanyContacts(companyId: string) {
  return useQuery(
    ["Company", "Contacts", companyId],
    (): Promise<ICompanyContact[]> => axios.get(`Company/${companyId}/contacts`),
    {
      enabled: !!companyId,
    }
  );
}
interface CompanyOccasionFilters {
  startDateFrom: Date;
  startDateTo: Date;
}
function useCompanyOccasions(companyId: string, filters: CompanyOccasionFilters) {
  const cleanedParams = cleanParamsLoop(filters);
  return useQuery(
    [
      "Company",
      "Occasions",
      companyId,
      { startDateFrom: filters.startDateFrom.toDateString(), startDateTo: filters.startDateTo.toDateString() },
    ],
    (): Promise<ICompanyOccasion[]> =>
      axios.get(`Company/${companyId}/occasions`, {
        params: cleanedParams,
        paramsSerializer: (params) => QueryString.stringify(params, { serializeDate: (d) => d.toJSON() }),
      }),
    {
      enabled: !!companyId,
      staleTime: 1000 * 60 * 10,
    }
  );
}
function useCompanyDocuments(companyId: string) {
  return useQuery(
    ["Company", "Documents", companyId],
    (): Promise<ICompanyDocument[]> => axios.get(`Company/${companyId}/documents`),
    {
      enabled: !!companyId,
    }
  );
}
function useCompanyAddresses(companyId: string) {
  return useQuery(
    ["Addresses", "Company", companyId],
    (): Promise<ICompanyAddress[]> => axios.get(`Company/${companyId}/addresses`)
  );
}

function useCompanyDelete() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const errorToast = useErrorToast();
  return useMutation((companyId: ICompany["id"]) => axios.delete(`Company/${companyId}`), {
    onSuccess: () => {
      toast({
        status: "success",
        title: t("Deleted"),
      });
      queryClient.invalidateQueries("Companies");
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useCompanyProfile(companyId: string) {
  return useQuery(
    ["Company", "Profile", companyId],
    (): Promise<ICompanyProfile> => axios.get(`Company/${companyId}/profile`),
    {
      enabled: !!companyId,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}

interface IEditCategory {
  body: { categoryId: GUID; companyId: GUID };
  isChecked: boolean;
}
function useEditCategories() {
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation(
    ({ body, isChecked }: IEditCategory) =>
      axios.post(`Company/${body.companyId}/categories/${isChecked ? "add" : "remove"}`, body),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["Company", variables.body.companyId]);
      },
      onError: (err: AxiosError, variables) => {
        errorToast(err);
      },
    }
  );
}

export interface IEditCompanyNameLogo {
  name: string;
  id: string;
  thumbnailBase64: string;
  thumbnailChanged: boolean;
}

function useEditCompanyNameLogo() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((body: IEditCompanyNameLogo) => axios.put(`Company/${body.id}`, body), {
    onSuccess: (res, variables) => {
      toast({
        status: "success",
        position: "bottom-left",
        title: t("Success"),
        description: t("Company Edited"),
      });
      queryClient.invalidateQueries(["Company", variables.id]);
      queryClient.invalidateQueries("Companies");
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

interface EditLicenceBody {
  numberOfLicences: number;
  id: string;
}
function useEditCompanyLicenceVolume() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();
  const errorToast = useErrorToast();
  return useMutation((body: EditLicenceBody) => axios.post(`Company/${body.id}/licencevolume`, body), {
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries(["Company", variables.id]);
      toast({
        status: "success",
        position: "bottom-left",
        title: t("Success"),
        description: t("Company Edited"),
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useCompanyUsers(companyId?: string) {
  return useQuery(
    ["Company", companyId, "users"],
    (): Promise<ICompanyUser[]> => axios.get(`Company/${companyId}/Users`),
    {
      enabled: !!companyId,
    }
  );
}

export interface ICompanyNewsFeedQueryParams {
  pageSize: number;
  pageNumber: number;
  sortOrder: "ASC" | "DESC";
}

function useCompanyNewsfeed(companyId: string, queryParams: ICompanyNewsFeedQueryParams) {
  const cleanedParams = cleanParamsLoop(queryParams);
  return useQuery(
    [companyId, "newsfeed", queryParams],
    (): Promise<INewsFeed> =>
      axios.get(`Company/${companyId}/newsposts`, {
        params: cleanedParams,
      })
  );
}

export interface ISupplierFilters {
  nameStartsWith?: string;
  categories?: string[];
  favouriteSuppliersOnly: boolean;
}

function useSuppliers(filters: ISupplierFilters) {
  const cleanedParams = cleanParamsLoop(filters);
  return useQuery(
    ["Suppliers", cleanedParams],
    (): Promise<ISupplier[]> =>
      axios.get("Company/suppliers", {
        params: cleanedParams,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export {
  useCreateCompany,
  useCompanyTypes,
  useCompany,
  useCompanies,
  useCompanyContacts,
  useCompanyOccasions,
  useCompanyDocuments,
  useCompanyAddresses,
  useEditCategories,
  useEditCompanyLicenceVolume,
  useEditCompanyNameLogo,
  useCompanyUsers,
  useCompanyProfile,
  useCompanyDelete,
  useCompanyNewsfeed,
  useSuppliers,
  useCompanyThumbnail,
};
