import { SearchIcon } from "@chakra-ui/icons";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value: string;
  handleChange: (value: string) => void;
}

const SearchInput = ({ value, handleChange }: Props) => {
  const { t } = useTranslation();
  return (
    <InputGroup flexBasis="300px">
      <Input placeholder={t("Search")} value={value} onChange={(e) => handleChange(e.target.value)} />
      <InputRightElement children={<SearchIcon></SearchIcon>} />
    </InputGroup>
  );
};

export { SearchInput };
