import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Circle, Collapse, Link, LinkProps, Spacer, Stack, useDisclosure } from "@chakra-ui/react";
import {
  filterDrafts,
  filterPending,
  filterRejected,
  useDrafts,
  useModerationQueue,
  useModerationQueueAll,
  usePendingModeration,
  useRejected,
} from "api/moderation";
import { useUserSelf } from "api/user";
import { LoadingError } from "components/LoadingError";
import { LoadingSpinner } from "components/LoadingSpinner";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink as RouterNavLink } from "react-router-dom";
import { UserRoles } from "types";
import { ERoutes } from "../Router/types";
import logo200 from "./ABF_Logo_200.png";
import { UserInfo } from "./UserInfo";

interface Props {}

const MenuLeft = (props: Props) => {
  const QUser = useUserSelf();
  if (QUser.isLoading) return <LoadingSpinner />;
  if (!QUser.isSuccess) return <LoadingError />;
  return (
    <Box h="calc(100vh - 100px)" w={220} bg="gray.200" position="relative" display="flex" flexDirection="column">
      <Box
        bg={"white"}
        p={4}
        display="flex"
        alignItems="center"
        h={200}
        // position="absolute"
        mt={-112}
        boxShadow="lg"
      >
        <img src={logo200} alt="Associated British Foods" />
      </Box>
      <Stack flexGrow={1} mt="4" spacing="2">
        {QUser.data.role.id === UserRoles.Supplier ? (
          <SupplierUserMenuItems />
        ) : QUser.data.role.id === UserRoles.Member ? (
          <MemberUserMenuItems />
        ) : QUser.data.role.id === UserRoles.Moderator ? (
          <ModeratorMenuItems />
        ) : QUser.data.role.id === UserRoles.Admin ? (
          <AdminMenuItems />
        ) : null}
        <Spacer />
        <Box>
          <UserInfo />
        </Box>
      </Stack>
    </Box>
  );
};

export default MenuLeft;

export function SupplierUserMenuItems() {
  const { t } = useTranslation();
  const QModerationQueue = useModerationQueueAll();
  const numberOfRejections = filterRejected(QModerationQueue.data ?? []).length;
  const numberOfDrafts = filterDrafts(QModerationQueue.data ?? []).length;
  const numberOfPending = filterPending(QModerationQueue.data ?? []).length;
  const totalNumber = numberOfDrafts + numberOfPending + numberOfRejections;
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <NavLink exact to={ERoutes.root}>
        {t("Home")}
      </NavLink>
      <SubMenuHeader onToggle={onToggle} isOpen={isOpen}>
        <Box display="flex">
          {t("Moderation")}{" "}
          {totalNumber ? (
            <Circle ml="3" size="24px" color="white" bg="brand.red">
              {totalNumber}
            </Circle>
          ) : null}
        </Box>
      </SubMenuHeader>
      <Collapse in={isOpen} animateOpacity>
        <NavLink to={ERoutes.pending} role="group" display="flex">
          {t("Pending")}
          {numberOfPending ? (
            <Circle ml="3" size="24px" color="white" bg="brand.red">
              {numberOfPending}
            </Circle>
          ) : null}
        </NavLink>
        <NavLink to={ERoutes.drafts} role="group" display="flex">
          {t("Drafts")}{" "}
          {numberOfDrafts ? (
            <Circle ml="3" size="24px" color="white" bg="brand.red">
              {numberOfDrafts}
            </Circle>
          ) : null}
        </NavLink>
        <NavLink to={ERoutes.rejected} role="group" display="flex">
          {t("Rejected")}{" "}
          {numberOfRejections ? (
            <Circle ml="3" size="24px" color="white" bg="brand.red">
              {numberOfRejections}
            </Circle>
          ) : null}
        </NavLink>
      </Collapse>
    </>
  );
}

export function MemberUserMenuItems() {
  const QModerationQueue = useModerationQueueAll();
  const numberOfRejections = filterRejected(QModerationQueue.data ?? []).length;
  const numberOfDrafts = filterDrafts(QModerationQueue.data ?? []).length;
  const numberOfPending = filterPending(QModerationQueue.data ?? []).length;
  const totalNumber = numberOfDrafts + numberOfPending + numberOfRejections;
  const { t } = useTranslation();
  const { data: userData } = useUserSelf();
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <NavLink exact to={ERoutes.root}>
        {t("News")}
      </NavLink>
      <NavLink to={ERoutes.events}>{t("Events")}</NavLink>
      <NavLink to={ERoutes.suppliers}>{t("Suppliers")}</NavLink>
      {userData?.valueTrackerAccess ? (
        <NavLink to={ERoutes.allCompanyValueTracker}>{t("Value Tracker")}</NavLink>
      ) : null}
      <SubMenuHeader onToggle={onToggle} isOpen={isOpen}>
        <Box display="flex">
          {t("Moderation")}
          {totalNumber ? (
            <Circle ml="3" size="24px" color="white" bg="brand.red">
              {totalNumber}
            </Circle>
          ) : null}
        </Box>
      </SubMenuHeader>
      <Collapse in={isOpen} animateOpacity>
        <NavLink to={ERoutes.pending} role="group" display="flex">
          {t("Pending")}
          {numberOfPending ? (
            <Circle ml="3" size="24px" color="white" bg="brand.red">
              {numberOfPending}
            </Circle>
          ) : null}
        </NavLink>
        <NavLink to={ERoutes.drafts} role="group" display="flex">
          {t("Drafts")}{" "}
          {numberOfDrafts ? (
            <Circle ml="3" size="24px" color="white" bg="brand.red">
              {numberOfDrafts}
            </Circle>
          ) : null}
        </NavLink>
        <NavLink to={ERoutes.rejected} role="group" display="flex">
          {t("Rejected")}{" "}
          {numberOfRejections ? (
            <Circle ml="3" size="24px" color="white" bg="brand.red">
              {numberOfRejections}
            </Circle>
          ) : null}
        </NavLink>
      </Collapse>
    </>
  );
}

export function ModeratorMenuItems() {
  const { t } = useTranslation();
  return (
    <>
      <NavLink to={ERoutes.root}>{t("Moderation Queue")}</NavLink>
    </>
  );
}

export function AdminMenuItems() {
  const { t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <NavLink exact to={ERoutes.root}>
        {t("News")}
      </NavLink>
      <NavLink to={ERoutes.events}>{t("Events")}</NavLink>
      <NavLink to={ERoutes.suppliers}>{t("Suppliers")}</NavLink>
      <NavLink to={ERoutes.allCompanyValueTracker}>{t("Value Tracker")}</NavLink>
      <NavLink to={ERoutes.moderation}>{t("Moderation Queue")}</NavLink>
      <SubMenuHeader isOpen={isOpen} onToggle={onToggle}>
        {t("Admin")}
      </SubMenuHeader>
      <Collapse in={isOpen} animateOpacity>
        <NavLink to={ERoutes.addCompany}>{t("Add Company")}</NavLink>
        <NavLink to={ERoutes.editCompanies}>{t("Edit Companies")}</NavLink>
        <NavLink to={ERoutes.adminUserManagement}>{t("Manage System Users")}</NavLink>
        <NavLink to={ERoutes.memberSiteManagement}>{t("Member Sites")}</NavLink>
        <NavLink to={ERoutes.SearchUsers}>{t("Search Users")}</NavLink>
        <NavLink to={ERoutes.categoryManagement}>{t("Category Management")}</NavLink>
        <NavLink to={ERoutes.countryManagement}>{t("Country Management")}</NavLink>
        <NavLink to={ERoutes.regionManagement}>{t("Region Management")}</NavLink>
        <NavLink to={ERoutes.invitationStatus}>{t("Invitation Status")}</NavLink>
      </Collapse>
    </>
  );
}

interface NavLinkProps extends LinkProps {
  children: React.ReactNode;
  to: string;
  exact?: boolean;
}

export const NavLink = ({ children, to, ...linkProps }: NavLinkProps) => {
  return (
    <Link
      display="block"
      py={2}
      px={3}
      to={to}
      borderRadius="xs"
      transition="all 0.3s"
      fontWeight="medium"
      lineHeight="1.5rem"
      as={RouterNavLink}
      _hover={{
        bg: "#D07681",
        color: "white",
      }}
      _activeLink={{
        bg: "brand.red",
        color: "white",
      }}
      {...linkProps}
    >
      {children}
    </Link>
  );
};

interface SubMenuHeaderProps {
  children: React.ReactNode;
  onToggle: () => void;
  isOpen: boolean;
}

function SubMenuHeader({ children, onToggle, isOpen }: SubMenuHeaderProps) {
  return (
    <Box
      cursor="pointer"
      gap="1em"
      alignItems="center"
      display="flex"
      _hover={{
        bg: "#D07681",
        color: "white",
      }}
      onClick={onToggle}
    >
      <Link
        display="block"
        py={2}
        px={3}
        _hover={{
          textDecoration: "none",
        }}
        borderRadius="xs"
        transition="all 0.3s"
        fontWeight="medium"
        lineHeight="1.5rem"
      >
        {children}
      </Link>
      {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
    </Box>
  );
}
