import * as React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

const colors = {
  brand: {
    red: "#c24e5c",
    grey: "#808184",
  },
};
const theme = extendTheme({ colors });

interface Props {}

function Chakra(props: PropsWithChildren<Props>) {
  return <ChakraProvider theme={theme}>{props.children}</ChakraProvider>;
}

export default Chakra;
