import { Button, Divider, Flex, Icon, Link, Skeleton, Stack, Text, Wrap, Image, Box } from "@chakra-ui/react";
import { useUserSelf } from "api/user";
import { LoadingError } from "components/LoadingError";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdExitToApp } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import { ERoutes } from "Router/types";
import IntellyncLogo from "./intellync_logo.png";
interface Props {}

export const UserInfo = (props: Props) => {
  const { t } = useTranslation();

  const QUser = useUserSelf();
  if (QUser.isIdle || QUser.isLoading) {
    return (
      <Skeleton>
        <Text fontWeight="medium">Name</Text>
        <Text fontSize="sm" lineHeight="shorter">
          Email
        </Text>
      </Skeleton>
    );
  }
  if (QUser.isError) {
    return <LoadingError />;
  }
  return (
    <>
      <Divider orientation="horizontal" color="gray.800" />
      <Stack spacing="2" px="2" mb="2" alignItems="center">
        <Flex direction="column" justifyContent="center" align="center">
          <Text fontWeight="medium">{QUser.data.name}</Text>
          <Text fontSize="sm" lineHeight="shorter">
            {QUser.data.username}
          </Text>
        </Flex>

        <Button variant="outline" colorScheme="grey.400" w="fit-content" as={RouterLink} to={ERoutes.logout}>
          {t("Logout")} <Icon ml="1" as={MdExitToApp} />
        </Button>

        <Wrap justify="center">
          <Link as={RouterLink} to={ERoutes.privacyPolicy} fontSize="xs">
            {t("Privacy Policy")}
          </Link>
          <Link as={RouterLink} to={ERoutes.terms} fontSize="xs">
            {t("Terms & Conditions")}
          </Link>
        </Wrap>
        <Box display="flex" alignItems="flex-end">
          <Text fontSize="xs">Powered By</Text>
          <Image sx={{ ml: 1 }} width="80px" src={IntellyncLogo} />
        </Box>
      </Stack>
    </>
  );
};
