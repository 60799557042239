import { Box, Heading } from "@chakra-ui/react";
import React from "react";

interface Props {
  errorMessage?: string;
  children?: React.ReactNode;
}

export const LoadingError = ({ errorMessage = "Something went wrong", children }: Props) => {
  return (
    <Box py="4" px="2">
      <Heading as="h5" pb="4" size="md">
        {errorMessage}
      </Heading>
      {children}
    </Box>
  );
};
