import { DeleteIcon, EditIcon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { IValueTracker } from "api/valueTracker";
import { DatePicker } from "components/DatePicker";
import { Filter } from "components/TableFilters";
import { format, isAfter, isBefore, isSameDay, isValid, isWithinInterval, subYears } from "date-fns";
import { SelectionAction } from "hooks/useSelect";
import { csvDownload } from "lib/csvDownload";
import * as React from "react";
import { useTranslation } from "react-i18next";

const currencyFormatter = new Intl.NumberFormat(undefined, {
  currency: "USD",
  style: "currency",
  maximumFractionDigits: 2,
  // minimumFractionDigits: 2,
  currencyDisplay: "narrowSymbol",
});

interface TableData extends Omit<IValueTracker, "startDate" | "endDate"> {
  startDate: Date;
  endDate: Date;
}

interface EditProps {
  data: IValueTracker[];
  isEditable: true;
  displaySupplier?: boolean;
  selectDispatch: React.Dispatch<SelectionAction<string>>;
}
interface ReadOnlyProps {
  data: IValueTracker[];
  isEditable: false;
  displaySupplier?: boolean;
  selectDispatch?: never;
}
type Props = EditProps | ReadOnlyProps;

const isWithinRange: FilterFn<TableData> = (row, columnId, value) => {
  const date = row.getValue<Date | null>(columnId);
  if (!date) return true;
  const [start, end] = value as [Date | null, Date | null];
  if (isValid(start) && isValid(end)) {
    return isWithinInterval(date, {
      // conditional checks for date validity and therefore removes chance of null
      start: start!,
      end: end!,
    });
  } else return true;
};
const weakIncludes: FilterFn<TableData> = (row, columnId, value) => {
  const rowValue = row.getValue<number | null>(columnId);
  if (!value) return true;
  if (rowValue?.toString().includes(value.toString())) return true;
  return false;
};

export default function ValueTrackerTable({ data, isEditable, selectDispatch, displaySupplier = false }: Props) {
  const { t } = useTranslation();

  const [startDateFrom, setStartDateFrom] = React.useState<Date | null>(subYears(new Date(), 1));
  const [endDateBy, setEndDateBy] = React.useState<Date | null>(null);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [filters, setFilters] = React.useState<ColumnFiltersState>([]);
  const procData = React.useMemo(
    () =>
      data
        .map((d) => ({ ...d, startDate: new Date(d.startDate), endDate: new Date(d.endDate) }))
        .filter((d) =>
          !startDateFrom ? true : isAfter(d.startDate, startDateFrom) || isSameDay(d.startDate, startDateFrom)
        )
        .filter((d) => (!endDateBy ? true : isBefore(d.endDate, endDateBy) || isSameDay(d.endDate, endDateBy))),
    [data, startDateFrom, endDateBy]
  );

  const columns = React.useMemo((): ColumnDef<TableData>[] => {
    return [
      {
        accessorKey: "supplierName",
        header: t("Supplier"),
      },
      {
        accessorKey: "memberSiteName",
        header: t("Member Site"),
      },
      {
        accessorKey: "initiativeDescription",
        header: t("Description"),
        cell: (props) => props.getValue(),
      },
      {
        accessorKey: "startDate",
        header: t("Start Date"),
        sortingFn: "datetime",
        cell: (props) => format(props.getValue(), "dd/MM/yyyy"),
        filterFn: isWithinRange,
      },
      {
        accessorKey: "endDate",
        sortingFn: "datetime",
        header: t("End Date"),
        cell: (props) => format(props.getValue(), "dd/MM/yyyy"),
        filterFn: isWithinRange,
      },
      {
        header: t("Country"),
        id: "countryName",
        accessorFn: (row) => row.country.name,
      },
      {
        header: t("Region"),
        id: "regionName",
        accessorFn: (row) => row.country.regionName,
      },
      {
        accessorKey: "benefit",
        header: t("Benefit ($)"),
        filterFn: weakIncludes,
        cell: (props) => (props.getValue<number | null>() ? currencyFormatter.format(props.getValue()) : null),
        footer: (props) => {
          const total = props.table
            .getSortedRowModel()
            .flatRows.reduce((acc, c) => acc + (c.getValue<number | null>(props.column.id) ?? 0), 0);
          return currencyFormatter.format(total) ?? null;
        },
      },
      {
        id: "addedValue",
        accessorFn: (row) => row.addedValue ?? "",
        header: t("Added Value"),
        filterFn: "includesString",
      },
      {
        id: "actions",
        cell: (props) => (
          <HStack>
            <IconButton
              variant="ghost"
              color="primary"
              onClick={() => {
                if (isEditable) {
                  selectDispatch({ type: "edit", payload: props.row.original.id });
                }
              }}
              aria-label="Edit"
              icon={<EditIcon />}
            />
            <IconButton
              color="primary"
              variant={"ghost"}
              onClick={() => {
                if (isEditable) {
                  selectDispatch({ type: "delete", payload: props.row.original.id });
                }
              }}
              aria-label="Delete"
              icon={<DeleteIcon />}
            />
          </HStack>
        ),
      },
    ];
  }, [isEditable, selectDispatch, t]);

  const table = useReactTable({
    data: procData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnFilters: filters,
      columnVisibility: {
        actions: isEditable,
        supplierName: displaySupplier,
      },
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  function prepareDateForExport(row: TableData | undefined): any | null {
    if (!row) return null;
    return {
      Supplier: row.supplierName,
      Site: row.memberSiteName,
      "Initiative Description": row.initiativeDescription,
      "Start Date": format(row.startDate, "dd/MM/yyyy"),
      "End Date": format(row.endDate, "dd/MM/yyyy"),
      Country: row.country.name,
      Region: row.country.regionName,
      Benefit: row.benefit,
      "Added Value": row.addedValue,
    };
  }

  function downloadFilteredList() {
    const filteredList = table
      .getFilteredRowModel()
      .flatRows.map((r) => prepareDateForExport(r.original))
      .filter((r) => r != null) as TableData[];
    csvDownload(filteredList);
  }
  return (
    <div className="p-2">
      <TableContainer bg={"white"}>
        <HStack mb={"2"} p="6">
          <Box>
            <label htmlFor="startDateFrom">{t("Start From")}</label>

            <DatePicker
              id="startDateFrom"
              dateFormat={"dd/MM/yyyy"}
              placeholderText={t("Start From")}
              onChange={(d) => setStartDateFrom(d as Date | null)}
              selected={startDateFrom}
              isClearable
            />
          </Box>

          <Box>
            <label htmlFor="startDateTo">{t("End By")} </label>

            <DatePicker
              dateFormat={"dd/MM/yyyy"}
              id="startDateTo"
              placeholderText={t("End by")}
              onChange={(d) => setEndDateBy(d as Date | null)}
              selected={endDateBy}
              isClearable
            />
          </Box>
        </HStack>
        <ChakraTable variant="striped">
          <Thead>
            <tr>
              {table.getFlatHeaders().map((header) => (
                <Th key={header.id} colSpan={header.colSpan}>
                  <div
                    {...{
                      className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <TriangleUpIcon />,
                      desc: <TriangleDownIcon />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                  {header.column.getCanFilter() ? (
                    <Box fontWeight={"revert"}>
                      <Filter column={header.column} table={table} />
                    </Box>
                  ) : null}
                </Th>
              ))}
            </tr>
          </Thead>
          <Tbody>
            {table.getSortedRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td isNumeric={typeof cell.getValue() === "number"} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => {
                  return (
                    <Td isNumeric key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder || header.id !== "benefit"
                        ? null
                        : flexRender(header.column.columnDef.footer, header.getContext())}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tfoot>
        </ChakraTable>
      </TableContainer>
      <Box mt={"3"}>
        <Button onClick={downloadFilteredList}>Export List</Button>
      </Box>
    </div>
  );
}
