import { useMsal } from "@azure/msal-react";
import { Button } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {}

const Login = (props: Props) => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const login = async () => {
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      instance.loginRedirect();
    } else {
      console.log("SOME ONE IS ALREADY SIGNED IN");
    }
  };

  return (
    <div>
      <Button
        id="login-button"
        onClick={login}
        bg="brand.red"
        color="white"
        _hover={{
          bg: "#D07681",
        }}
      >
        {t("Sign-in")}
      </Button>
    </div>
  );
};

export default Login;
