import { Box, BoxProps, Spinner } from "@chakra-ui/react";
import React from "react";

interface Props extends BoxProps {}

const LoadingSpinner = (props: Props) => {
  return (
    <Box width="100%" my={4} display="flex" justifyContent="center" alignItems="center">
      <Spinner />
    </Box>
  );
};

export { LoadingSpinner };
