import React from "react";
import { useLocation } from "react-router-dom";

export function useGoogleAnalyticsEventLogger(
  pageTitle: string,
  eventCategory: string
) {
  const location = useLocation();
  React.useEffect(() => {
    window.gtag("event", eventCategory, {
      page_title: pageTitle,
      page_location: location.search,
      page_path: location.pathname,
      event_category: eventCategory,
    });
  }, [pageTitle, eventCategory, location]);
}
