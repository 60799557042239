import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Image as ChakraImage,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
const byteSize = (str: string) => new Blob([str]).size;

const Base64ImageUpload = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  name,
  control,
  imageWidth = 300,
}: {
  imageWidth?: number;
} & UseControllerProps<TFieldValues, TName>) => {
  const {
    field,
    fieldState: { invalid },
  } = useController({ name, control });
  const inputRef = React.useRef<HTMLInputElement>(null);
  const toast = useToast();
  const { t } = useTranslation();
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target?.files?.[0];
    if (reader && file) {
      const img = new Image();
      reader.onloadend = () => {
        img.src = reader.result as string;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (img.width > imageWidth) {
            const aspectRatio = imageWidth / img.width;
            img.width = imageWidth;
            img.height *= aspectRatio;
          }
          canvas.height = img.height;
          canvas.width = img.width;
          ctx?.drawImage(img, 0, 0, img.width, img.height);
          const base64Image = canvas.toDataURL(file.type, 0.9);

          const imageSize = byteSize(base64Image);
          console.log(imageSize);
          if (imageSize > 256000) {
            toast({
              status: "error",
              title: t("Image too large"),
              description: t("Please use an image smaller than 256kb"),
            });
          } else {
            field.onChange(canvas.toDataURL(file.type, 0.9));
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <FormControl isInvalid={invalid} isRequired width="auto" alignSelf="flex-start">
      <FormLabel htmlFor="imageUpload">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          name={name}
          ref={inputRef}
          id="imageUpload"
          style={{ display: "none" }}
        ></input>
        <Button onClick={() => inputRef.current?.click()}>{t("Choose image")}</Button>
      </FormLabel>
      {field.value ? (
        <IconButton
          type="button"
          onClick={() => field.onChange("")}
          aria-label="remove image"
          icon={<DeleteIcon />}
        ></IconButton>
      ) : null}
    </FormControl>
  );
};

interface ImagePreviewProps extends BoxProps {
  name: string;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

const ImagePreview = ({ name, control, setValue, ...boxProps }: ImagePreviewProps) => {
  const thumbnailBase64 = useWatch({ name, control });
  return thumbnailBase64 ? (
    <Box {...boxProps}>
      <ChakraImage src={thumbnailBase64} alt="Company logo" />
    </Box>
  ) : null;
};

export { Base64ImageUpload, ImagePreview };
