import { useUserSelf } from "api/user";

export function useIsOwner(companyId?: string): boolean {
  // we'll check the user account to see if the supplier id matches the company user belongs to
  const QUser = useUserSelf();

  // TODO Replace this to false as an admin user has no userCompany
  if (!QUser.data?.userCompany) {
    return false;
  }
  return QUser.data?.userCompany.companyId === companyId;
}
