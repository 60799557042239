import { QuestionIcon } from "@chakra-ui/icons";
import { Box, Link } from "@chakra-ui/layout";
import { useUserSelf } from "api/user";
import { LanguageSelect } from "locales/LanguageSelect";
import React from "react";
import { useTranslation } from "react-i18next";
import { UserRoles } from "types";

interface Props {}

const ABFTopBar = (props: Props) => {
  const QUser = useUserSelf();
  const { i18n, t } = useTranslation();
  const lang = i18n.language.substring(0, 2);
  const member = QUser.data?.role.id === UserRoles.Member;
  const memberSupplierUser = member || QUser.data?.role.id === UserRoles.Supplier;

  return (
    <Box alignItems="center">
      {memberSupplierUser ? (
        <Link
          href={`https://stintellyncsharedprod.blob.core.windows.net/guides/${lang ?? "en"}/abfhub/${
            member ? "member" : "supplier"
          }/ABFHubGuide.pdf`}
          download={t("ABF Hub User Guide.pdf")}
          fontSize="sm"
          flexGrow={1}
          isExternal
          minW="100px"
        >
          {t("User Guide")} <QuestionIcon />
        </Link>
      ) : null}
      <LanguageSelect />
    </Box>
  );
};

export default ABFTopBar;
