import { useToast } from "@chakra-ui/react";
import axios from "lib/axios";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { DateString, GUID } from "types";

export interface IValueTracker {
  id: GUID;
  supplierId: GUID;
  supplierName: string;
  memberSiteId: GUID;
  memberSiteName: string;
  initiativeDescription: string;
  startDate: DateString;
  endDate: DateString;
  country: {
    id: GUID;
    name: string;
    regionId: GUID;
    regionName: string;
  };
  benefit: number | null;
  addedValue: string | null;
}

export function useValueTracker() {
  return useQuery(["valuetracker"], (): Promise<IValueTracker[]> => axios.get("ValueTracker"), {
    refetchOnWindowFocus: false,
  });
}
export function useSupplierValueTracker(supplierId: string) {
  return useQuery(
    ["valuetracker", supplierId],
    (): Promise<IValueTracker[]> => axios.get(`ValueTracker/supplier/${supplierId}`),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useDeleteValueTrackerItem() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  return useMutation((id: string): Promise<void> => axios.delete(`ValueTracker/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["valuetracker"]);
      toast({
        status: "success",
        title: t("Success"),
        description: t("Value Item Deleted"),
        isClosable: true,
        position: "bottom-left",
      });
    },
  });
}
export interface IValueTrackerCreate {
  supplierId: GUID;
  memberSiteId: GUID;
  initiativeDescription: string;
  startDate: string | Date;
  endDate: string | Date;
  benefit?: number | null;
  addedValue?: string | null;
}
export function useCreateValueTrackerItem() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  return useMutation((valueTracker: IValueTrackerCreate): Promise<void> => axios.post("ValueTracker", valueTracker), {
    onSuccess: () => {
      queryClient.invalidateQueries(["valuetracker"]);
      toast({
        status: "success",
        title: t("Success"),
        description: t("Value Item Created"),
        isClosable: true,
        position: "bottom-left",
      });
    },
  });
}

export interface IValueTrackerUpdate extends IValueTrackerCreate {
  id: GUID;
}
export function useUpdateValueTrackerItem() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  return useMutation(
    (valueTracker: IValueTrackerUpdate): Promise<void> => axios.put(`ValueTracker/${valueTracker.id}`, valueTracker),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["valuetracker"]);
        toast({
          status: "success",
          title: t("Success"),
          description: t("Value Item Updated"),
          isClosable: true,
          position: "bottom-left",
        });
      },
    }
  );
}
