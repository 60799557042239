import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function TextWithLabel({ label, text }: { label: string; text?: string }) {
  const { t } = useTranslation();
  return (
    <Box>
      <Text fontSize="xs" fontWeight="bold" textTransform="uppercase">
        {t(label)}
      </Text>
      <Text>{text}</Text>
    </Box>
  );
}
