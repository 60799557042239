import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Grid,
  Heading,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useAuthorDetails, useNewsFeed, useNewsItem } from "api/newsfeed";
import { useUserSelf } from "api/user";
import { LoadingError } from "components/LoadingError";
import { LoadingSpinner } from "components/LoadingSpinner";
import RichTextRender from "components/RichTextRender";
import { useGoogleAnalyticsEventLogger } from "hooks/useGoogleAnalyticsEventLogger";
import { useIsOwner } from "hooks/useIsOwner";
import { formatDate } from "lib/dateHelpers";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ERoutes } from "Router/types";
import { CompanyTypes, UserRoles } from "types";

const newsFeedParams = {
  pageSize: 6,
  pageNumber: 1,
  sortOrder: "DESC" as "DESC",
  regions: [],
};
interface Props {}

const Post = (props: Props) => {
  const { postId } = useParams<{ postId: string }>();
  const QUser = useUserSelf();
  const QPost = useNewsItem(postId);
  const QPosts = useNewsFeed(newsFeedParams);
  const isOwner = useIsOwner(QPost.data?.approvedNewsPost?.companyId);
  const QAuthorDetails = useAuthorDetails(
    QPost.data?.approvedNewsPost?.author.id
  );
  const { t } = useTranslation();

  useGoogleAnalyticsEventLogger(
    QPost.data?.approvedNewsPost.title ?? "unknown",
    "news_page_view"
  );

  return (
    <div id="post-wrapper">
      {QPost.isLoading || QPost.isIdle ? (
        <LoadingSpinner />
      ) : QPost.isError ? (
        <LoadingError />
      ) : (
        <>
          {isOwner ? (
            <Box justifyContent="flex-end" display="flex" mb="2">
              <Button variant="outline" as={Link} to={`${QPost.data.id}/edit`}>
                {t("Edit")}
              </Button>
            </Box>
          ) : null}
          <Grid
            alignItems="start"
            gap="2em"
            gridTemplateColumns={
              QUser.data?.role.id === UserRoles.Member ? "1fr 250px" : "1fr"
            }
          >
            <Box as="article" bg="white" padding="8" borderRadius="xl">
              {QPost.data.approvedNewsPost?.thumbnail ? (
                <Image src={QPost.data.approvedNewsPost.thumbnail} />
              ) : null}
              <Heading mb={2}>{QPost.data.approvedNewsPost.title}</Heading>
              {QPost.data.approvedNewsPost.companyType?.id ===
              CompanyTypes.Supplier ? (
                <Link
                  to={`${ERoutes.supplier}/${QPost.data.approvedNewsPost.companyId}`}
                >
                  <Heading
                    as="h4"
                    size="sm"
                    mb={2}
                    _hover={{ color: "brand.red", textDecor: "underline" }}
                  >
                    {QPost.data.approvedNewsPost.companyName}
                  </Heading>
                </Link>
              ) : (
                <Heading as="h4" size="sm" mb={2}>
                  {QPost.data.approvedNewsPost.companyName}
                </Heading>
              )}
              <Box mb="2">
                <Popover placement="bottom-start">
                  <PopoverTrigger>
                    <Heading
                      mb="2"
                      as="h3"
                      fontSize="md"
                      display="inline-flex"
                      alignItems="center"
                      cursor="pointer"
                      _hover={{ color: "brand.red" }}
                    >
                      {QPost.data.approvedNewsPost.author.name}{" "}
                      <InfoIcon ml="1" boxSize="0.8em" />
                    </Heading>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverHeader fontWeight="semibold">
                      {QAuthorDetails.data?.name}
                    </PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Text>{QAuthorDetails.data?.jobTitle}</Text>
                      <Text>{QAuthorDetails.data?.email}</Text>
                      <Text>{QAuthorDetails.data?.telephone}</Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
                <Text fontSize="smaller">
                  {t("posted:")}{" "}
                  {formatDate(QPost.data.approvedNewsPost.newsPostDate)}
                </Text>
              </Box>
              <Box>
                <HStack spacing="4">
                  {QPost.data.regions?.map((region) => (
                    <Tag key={region.id}>{region.name}</Tag>
                  ))}
                </HStack>
              </Box>
              <Box my="2">
                <RichTextRender
                  richText={QPost.data.approvedNewsPost.description}
                />
              </Box>
              <Box mt="2" mb="4">
                <RichTextRender
                  richText={QPost.data.approvedNewsPost.content}
                />
              </Box>
            </Box>
            {QUser.data?.role.id === UserRoles.Member ? (
              <Box as="article" bg="white" padding="8" borderRadius="xl">
                <Heading size="md" as="h3" mb="5">
                  {t("Latest Posts")}
                </Heading>

                {QPosts.isIdle || QPosts.isLoading ? (
                  <LoadingSpinner />
                ) : QPosts.isError ? (
                  <LoadingError />
                ) : (
                  QPosts.data.items.map((post) => (
                    <Box key={post.id} my="5">
                      <Link to={`${ERoutes.newsfeed}/${post.id}`}>
                        <Heading as="h4" size="sm" mb="2">
                          {post.title}
                        </Heading>
                        <Text mb="1" fontSize="small">
                          {post.companyName}
                        </Text>
                        <Text mb="2" fontSize="small">
                          {formatDate(post.postDateTime)}
                        </Text>
                      </Link>
                      <Divider />
                    </Box>
                  ))
                )}
              </Box>
            ) : null}
          </Grid>
        </>
      )}
    </div>
  );
};

export default Post;
