import { Box, BoxProps, Heading, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ERoutes } from "Router/types";
import { ISearch } from "types";
import { SearchHeading } from "./SearchHeading";

interface Props extends BoxProps {
  posts: ISearch["newsPosts"];
}

export const Posts = ({ posts, ...boxProps }: Props) => {
  const { t } = useTranslation();
  if (posts.length === 0) return null;
  return (
    <Box {...boxProps}>
      <SearchHeading>{t("Posts")}</SearchHeading>
      {posts.map((post) => (
        <LinkBox key={post.newsPostId} _hover={{ bg: "gray.200" }} px="1" py="2">
          <Text fontWeight="semibold">
            <LinkOverlay as={Link} to={`${ERoutes.newsfeed}/${post.newsPostId}`}>
              {post.newsPostTitle}
            </LinkOverlay>
          </Text>
          <Text fontSize="small">{post.companyName}</Text>
        </LinkBox>
      ))}
    </Box>
  );
};
