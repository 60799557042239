import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ButtonGroup,
  VStack,
  Textarea,
} from "@chakra-ui/react";
import { useContact, useEditContact } from "api/contacts";
import { EditEntityMeta } from "components/EditEntityMeta";
import { useEditButtonText } from "lib/editButtonText";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ICompanyContact } from "types";

interface Props {
  contact: ICompanyContact;
  open: boolean;
  handleClose: () => void;
}

interface FormTypes {
  id: string;
  name: string;
  jobTitle: string;
  emailAddress: string;
  geography: string;
  comment: string;
  telephone: string;
}

const EditContactModal = ({ contact, open, handleClose }: Props) => {
  const { t } = useTranslation();
  const editContact = useEditContact();
  const QContact = useContact(contact.id);
  const buttonText = useEditButtonText(QContact.data?.proposedContact?.moderationStatus?.id);
  const { isOpen, onClose } = useDisclosure({ isOpen: open, onClose: handleClose });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: {
      id: contact.id,
      name: contact.name,
      jobTitle: contact.jobTitle,
      emailAddress: contact.emailAddress,
      geography: contact.geography,
      telephone: contact.telephone,
      comment: "",
    },
  });

  React.useEffect(() => {
    if (QContact.data && QContact.data.proposedContact) {
      reset({
        id: QContact.data.id,
        name: QContact.data.proposedContact.name,
        jobTitle: QContact.data.proposedContact.jobTitle,
        emailAddress: QContact.data.proposedContact.emailAddress,
        geography: QContact.data.proposedContact.geography,
        telephone: QContact.data.proposedContact.telephone,
        comment: "",
      });
    }
  }, [QContact.data, reset]);

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);
  function onSubmit(data: FormTypes) {
    editContact.mutate(
      { ...data, isDraft: false },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  }
  function onSubmitDraft(data: FormTypes) {
    editContact.mutate(
      { ...data, isDraft: true },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  }

  return (
    <>
      <Modal isCentered isOpen={isOpen} size="lg" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Edit Contact")}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <EditEntityMeta meta={QContact.data?.proposedContact} />
              <VStack width="full" spacing="4">
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel htmlFor="name">{t("Name")}</FormLabel>
                  <Input {...register("name", { required: "Name is required" })} id="name" />
                  <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.emailAddress}>
                  <FormLabel htmlFor="email">{t("Email")}</FormLabel>
                  <Input {...register("emailAddress", { required: "Email is required" })} id="email" />
                  <FormErrorMessage>{errors?.emailAddress?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.telephone}>
                  <FormLabel htmlFor="telephone">{t("Telephone")}</FormLabel>
                  <Input {...register("telephone", { required: t("Required") as string })} id="telephone" />
                  <FormErrorMessage>{errors?.telephone?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.jobTitle}>
                  <FormLabel htmlFor="title">{t("Job Title")}</FormLabel>
                  <Input {...register("jobTitle", { required: "Title is required" })} id="title" />
                  <FormErrorMessage>{errors?.jobTitle?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.geography}>
                  <FormLabel htmlFor="geography">{t("Region")}</FormLabel>
                  <Input
                    {...register("geography", { required: t("Geography is required") as string })}
                    id="geography"
                  ></Input>
                  <FormErrorMessage>{!!errors?.geography?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.comment}>
                  <FormLabel htmlFor="comment">{t("Comment")}</FormLabel>
                  <Input {...register("comment")} id="comment" />
                  <FormErrorMessage>{errors?.comment?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup justifyContent="flex-end" alignSelf="flex-end" mt={4}>
                <Button onClick={handleSubmit(onSubmitDraft)} alignSelf="flex-end" type="submit">
                  {buttonText.saveDraftText}
                </Button>
                <Button onClick={handleSubmit(onSubmit)} alignSelf="flex-end" colorScheme="blue" type="submit">
                  {buttonText.submitText}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditContactModal;
