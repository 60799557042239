import { Box, Button, Stack } from "@chakra-ui/react";
import { UpsertValueItem, FormTypes } from "components/Entitites/valueTracker/UpsertValueItem";
import { ConfirmDeleteValueItem } from "components/Entitites/valueTracker/ConfirmDeleteValueItem";

import ValueTrackerTable from "components/Entitites/valueTracker/ValueTrackerTable";
import { useIsOwner } from "hooks/useIsOwner";
import { useSelection } from "hooks/useSelect";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSupplierValueTracker } from "api/valueTracker";

interface Props {
  companyId: string;
}

export const CompanyValueTracker = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const isOwner = useIsOwner(companyId);
  const QTableData = useSupplierValueTracker(companyId);
  const [valueTrackerState, dispatch] = useSelection<string>(null);
  const upsertModalOpen = valueTrackerState.mode === "create" || valueTrackerState.mode === "edit";
  const selected = QTableData.data?.find((item) => item.id === valueTrackerState.selection) ?? null;
  return (
    <div id="value-tracker-wrapper">
      {isOwner ? (
        <Stack>
          {valueTrackerState.mode === "create" ? (
            <Button alignSelf="flex-end" onClick={() => dispatch({ type: "reset" })}>
              {t("Cancel")}
            </Button>
          ) : (
            <Button alignSelf="flex-end" onClick={() => dispatch({ type: "create" })}>
              {t("Add Engagement")}
            </Button>
          )}
        </Stack>
      ) : null}
      <Box>
        {isOwner ? (
          <ValueTrackerTable isEditable={true} selectDispatch={dispatch} data={QTableData.data ?? []} />
        ) : (
          <ValueTrackerTable isEditable={false} data={QTableData.data ?? []} />
        )}
      </Box>
      {upsertModalOpen ? (
        <UpsertValueItem valueItem={selected} isOpen={upsertModalOpen} onClose={() => dispatch({ type: "reset" })} />
      ) : null}

      <ConfirmDeleteValueItem
        key={selected?.id ?? ""}
        valueItem={selected}
        isOpen={valueTrackerState.mode === "delete"}
        onClose={() => dispatch({ type: "reset" })}
      />
    </div>
  );
};
