import { EventType, InteractionType } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Box, Heading, Image, useToast } from "@chakra-ui/react";
import logo from "Layout/ABF_Logo_768.png";
import { LoadingSpinner } from "components/LoadingSpinner";
import React, { PropsWithChildren, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Login from "./components/Login";
import LoggedIn from "./LoggedIn";
import { forgotPasswordRequest, msalConfig } from "./utils/config";

interface Props {}

const LandingPage = (props: PropsWithChildren<Props>) => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const toast = useToast();
  useEffect(() => {
    const callbackId = instance.addEventCallback(async (message: any) => {
      if (message.interactionType === InteractionType.Redirect) {
        if (message.eventType === EventType.LOGIN_FAILURE) {
          if (message.error.errorMessage) {
            console.error(message.error.errorMessage);
            if (message.error.errorMessage.indexOf("AADB2C90118") > -1) {
              await instance.loginRedirect(forgotPasswordRequest).then(() => {
                // THESE NEVER RESOLVE BECAUSE THE PAGE IS CONSTANTLY BEING REFRESHED
                // THE PROMISE IS ABANDONED (IT SEEMS, DON'T KNOW WHY IT WOUD RETURNA PROMISE THEN)
                window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
              });
            }
          }
        } else if (message.eventType === EventType.LOGIN_SUCCESS) {
          const tfp = message?.payload.account.idTokenClaims.tfp;
          if (tfp === process.env.REACT_APP_AUTH_PROFILE_PASSWORD) {
            await instance.logoutRedirect();
          }
        } else if (message.eventType === EventType.LOGOUT_SUCCESS) {
          const tfp = message?.payload.account.idTokenClaims.tfp;
          if (tfp !== process.env.REACT_APP_AUTH_PROFILE) {
            // SOMETHING LOGGED OUT THAT WASN'T NORMAL - LOGGING BACK IN
            await instance.loginRedirect();
          } else {
            console.log("A REGULAR LOG OUT OCCURRED");
          }
          // COULD USE THIS POTENTIALLY IF THEY GET AN ERROR WHILE GETTING A TOKEN
          // else if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
          //   instance.logoutRedirect();
          // }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <AuthenticatedTemplate>
        <LoggedIn>{props.children}</LoggedIn>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Box bg="gray.100" w="full" height="100vh" display="flex" justifyContent="center" alignItems="center">
          <Box bg="white" width="500px" display="flex" flexDir="column" alignItems="center" p="8" borderRadius="xl">
            <Image mb="3" src={logo} alt="ABF Logo"></Image>
            <Heading color="brand.red" mb="3" as="h1" size="xl">
              ABF Hub
            </Heading>
            <Heading as="h2" size="md">
              {t("Please sign-in")}
            </Heading>
            <br />
            <Login />
          </Box>
        </Box>
      </UnauthenticatedTemplate>
    </Suspense>
  );
};

export default LandingPage;
