import React, { PropsWithChildren, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { BrowserRouter as Router } from "react-router-dom";
import Auth from "./Auth";
import Chakra from "./Chakra";
import { UserProvider } from "./User/UserProvider";

interface Props {
  children: ReactNode;
}
const queryClient = new QueryClient();
const Providers = (props: PropsWithChildren<Props>) => {
  return (
    <Chakra>
      <Router>
        <Auth>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <UserProvider>{props.children}</UserProvider>
          </QueryClientProvider>
        </Auth>
      </Router>
    </Chakra>
  );
};

export default Providers;
