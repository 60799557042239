import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import React, { PropsWithChildren } from "react";
import { AuthenticatedApiWrapper } from "./AuthenticatedApiWrapper";
import AuthError from "./components/AuthError";
import AuthLoading from "./components/AuthLoading";
import { loginRequest } from "./utils/config";

interface Props {}

const LoggedIn = (props: PropsWithChildren<Props>) => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={AuthError}
      loadingComponent={AuthLoading}
    >
      <AuthenticatedApiWrapper>{props.children}</AuthenticatedApiWrapper>
    </MsalAuthenticationTemplate>
  );
};

export default LoggedIn;
