import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "lib/axios";
import { GUID, IMemberSite } from "types";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useErrorToast } from "hooks/useErrorToast";
import { AxiosError } from "axios";
function useMemberSites() {
  return useQuery(["memberSites"], (): Promise<IMemberSite[]> => axios.get("MemberSite"));
}

export type CreateMemberSiteBody = { siteName: string; countryId: GUID };
function useCreateSite() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const errorToast = useErrorToast();
  return useMutation((site: CreateMemberSiteBody) => axios.post("MemberSite", site), {
    onSuccess: () => {
      queryClient.invalidateQueries(["memberSites"]);
      toast({
        status: "success",
        title: t("Success"),
        description: t("Site has been created"),
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

export type EditSiteBody = Pick<IMemberSite, "id" | "siteName" | "countryId">;
function useEditSite() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const errorToast = useErrorToast();
  return useMutation((site: EditSiteBody) => axios.put(`MemberSite/${site.id}`, site), {
    onSuccess: () => {
      queryClient.invalidateQueries(["memberSites"]);
      toast({
        status: "success",
        title: t("Success"),
        description: t("Site has been updated"),
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

function useDeleteSite() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const errorToast = useErrorToast();
  return useMutation((site: IMemberSite["id"]) => axios.delete(`MemberSite/${site}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["memberSites"]);
      toast({
        status: "success",
        title: t("Success"),
        description: t("Site has been deleted"),
      });
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

export { useMemberSites, useCreateSite, useEditSite, useDeleteSite };
