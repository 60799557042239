import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, HStack, IconButton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  currentPage: number;
  totalPages: number;
  handlePageChange: (type: "forward" | "back") => void;
}

function PaginationButtons({ currentPage, totalPages, handlePageChange }: Props) {
  const { t } = useTranslation();
  return (
    <Box mt="5" display="flex" justifyContent="center">
      <HStack spacing="2" alignItems="center">
        <IconButton
          onClick={() => handlePageChange("back")}
          disabled={currentPage === 1}
          variant="ghost"
          aria-label="previous page"
          icon={<ChevronLeftIcon />}
        />
        <Text>
          {t("Page")} {currentPage} {t("of")} {totalPages}
        </Text>
        <IconButton
          onClick={() => handlePageChange("forward")}
          disabled={currentPage === totalPages || totalPages === 0}
          variant="ghost"
          aria-label="next page"
          icon={<ChevronRightIcon />}
        />
      </HStack>
    </Box>
  );
}

export { PaginationButtons };
