import { useQuery } from "react-query";
import axios from "lib/axios";
import { ISearch } from "types";

function useSearch(term: string) {
  return useQuery(
    ["Search", term],
    (): Promise<ISearch> =>
      axios.get("Search", {
        params: {
          term,
        },
      }),
    {
      enabled: term.length >= 3,
    }
  );
}

export { useSearch };
