import { Box, Divider, Heading, HStack, LinkBox, LinkOverlay, Stack, Tag, Text, Wrap } from "@chakra-ui/react";
import { useUserSelf } from "api/user";
import { FavouriteOccasionToggle } from "components/FavouriteOccasionToggle/FavouriteOccasionToggle";
import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { ERoutes } from "Router/types";
import { IUserOccasion, UserRoles } from "types";
interface Props {
  event: IUserOccasion;
}

const EventCard = ({ event }: Props) => {
  const QUser = useUserSelf();
  const formattedDate = React.useMemo(() => format(new Date(event.startDate), "dd/MM/yyyy H:mm"), [event.startDate]);
  return (
    <LinkBox>
      <Box py={6} px={4} bg="white" shadow="md" rounded="lg">
        <Stack>
          <Heading as="h3" size="md">
            <LinkOverlay as={Link} to={`/events/${event.id}`}>
              {event.name}
            </LinkOverlay>
          </Heading>
          <Divider />
          <Link to={`${ERoutes.supplier}/${event.companyId}`}>
            <Heading as="h4" size="sm" _hover={{ textDecor: "underline" }}>
              {event.companyName}
            </Heading>
          </Link>

          <HStack spacing="0.5" wrap="wrap">
            <Text fontSize="sm">{formattedDate}</Text>
          </HStack>
          <Wrap spacing="2">
            {event.regions.map((region) => (
              <Tag key={region.id} fontSize="xs">
                {region.name}
              </Tag>
            ))}
          </Wrap>
        </Stack>
        {QUser.data?.role.id === UserRoles.Member ? (
          <Box mt="3">
            <FavouriteOccasionToggle occasionId={event.id} isFavourite={event.isFavouriteOccasion} />
          </Box>
        ) : null}
      </Box>
    </LinkBox>
  );
};

export { EventCard };
