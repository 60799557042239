import { Box, Button, ButtonGroup, FormControl, FormErrorMessage, FormLabel, Input, VStack } from "@chakra-ui/react";
import { useCreateOccasion } from "api/occasions";
import { useRegions } from "api/regions";
import { useUserSelf } from "api/user";
import { DatePicker } from "components/DatePicker";
import { addHours, isAfter, isValid, startOfHour } from "date-fns";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import toDate from "date-fns-tz/toDate";
import utcToZonedTime from "date-fns-tz/utcToZonedTime/index.js";
import { resizeImage } from "lib/resizeImage";
import React from "react";
import { Controller, NestedValue, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import TimezoneSelect from "react-timezone-select";
import SunEditor from "suneditor-react";
import lang from "suneditor-react/dist/types/lang";
import SunEditorCore from "suneditor/src/lib/core";

import { IdName } from "types";

interface Props {}

interface TimeZone {
  value: string;
  label: string;
  abbrev: string;
  offset: number;
  altName: string;
}

interface FormTypes {
  companyId: string;
  name: string;
  description: string;
  timezone: string | TimeZone;
  startDate: Date;
  endDate: Date;
  regions: NestedValue<IdName[]>;
}

const AddEvent = (props: Props) => {
  let editorRef = React.useRef<SunEditorCore | null>(null);

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editorRef.current = sunEditor;
  };
  const QUser = useUserSelf();
  const { t, i18n } = useTranslation();
  const QRegions = useRegions();
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormTypes>({
    defaultValues: {
      companyId: QUser.data?.userCompany?.companyId ?? "",
      name: "",
      description: "",
      timezone: "",
      startDate: startOfHour(new Date()),
      endDate: startOfHour(addHours(new Date(), 1)),
      regions: [],
    },
  });

  React.useEffect(() => {
    const existingValues = getValues();
    if (QUser.data?.userCompany?.companyId) {
      reset({ ...existingValues, companyId: QUser.data?.userCompany?.companyId });
    }
  }, [QUser.data, reset, getValues]);

  const startDate = watch("startDate");
  React.useEffect(() => {
    setValue("endDate", addHours(startDate, 1));
  }, [startDate, setValue]);

  const createOccasion = useCreateOccasion();
  function onSubmit(data: FormTypes) {
    const startDate = zonedTimeToUtc(
      data.startDate,
      typeof data.timezone === "string" ? data.timezone : data.timezone.value
    );
    const endDate = zonedTimeToUtc(
      data.endDate,
      typeof data.timezone === "string" ? data.timezone : data.timezone.value
    );
    createOccasion.mutate(
      {
        ...data,
        startDate,
        endDate,
        regions: data.regions.map((region) => region.id),
        timezone: typeof data.timezone === "string" ? data.timezone : data.timezone.value,
        isDraft: false,
      },
      {
        onSuccess: () => {
          reset();
          editorRef.current?.setContents("");
        },
      }
    );
  }
  function onSubmitDraft(data: FormTypes) {
    const startDate = zonedTimeToUtc(
      data.startDate,
      typeof data.timezone === "string" ? data.timezone : data.timezone.value
    );
    const endDate = zonedTimeToUtc(
      data.endDate,
      typeof data.timezone === "string" ? data.timezone : data.timezone.value
    );
    createOccasion.mutate(
      {
        ...data,
        startDate,
        endDate,
        regions: data.regions.map((region) => region.id),
        timezone: typeof data.timezone === "string" ? data.timezone : data.timezone.value,
        isDraft: true,
      },
      {
        onSuccess: () => {
          reset();
          editorRef.current?.setContents("");
        },
      }
    );
  }

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack width="full" spacing="4">
            <FormControl isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">{t("Name")}</FormLabel>
              <Input {...register("name", { required: "Name is required" })} id="name" />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.description}>
              <FormLabel htmlFor="description">{t("Description")}</FormLabel>
              <Controller
                control={control}
                name="description"
                rules={{
                  required: "Required",
                }}
                render={({ field }) => {
                  const { ref, ...fieldRest } = field;
                  return (
                    <SunEditor
                      lang={i18n.language as lang}
                      height="100%"
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize", "formatBlock"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                            "fontColor",
                            "hiliteColor",
                            "removeFormat",
                          ],
                          ["align", "horizontalRule", "outdent", "indent", "list"],
                          ["table", "link", "image", "video"],
                        ],
                      }}
                      onImageUploadBefore={(files, _, uploadHandler) => {
                        resizeImage(files, uploadHandler);
                      }}
                      autoFocus={false}
                      defaultValue={""}
                      {...fieldRest}
                      getSunEditorInstance={getSunEditorInstance}
                    />
                  );
                }}
              />
              <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.timezone}>
              <FormLabel htmlFor="timezone">{t("TimeZone")}</FormLabel>
              <Controller
                name="timezone"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => <TimezoneSelect value={field.value} onChange={field.onChange} />}
              />
              <FormErrorMessage>{errors?.timezone?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.startDate}>
              <FormLabel htmlFor="startDate">{t("Start")}</FormLabel>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <DatePicker
                    dateFormat="dd/MM/yyyy H:mm"
                    selected={field.value}
                    showTimeInput
                    onChange={(date: Date) => field.onChange(date)}
                  />
                )}
              />
              <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.endDate}>
              <FormLabel htmlFor="endDate">{t("End")}</FormLabel>
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    mustBeDate: (val) => isValid(val) || "Must Be a valid Date",
                    afterStart: (val) => {
                      const startDate = getValues("startDate");
                      return (isValid(startDate) && isAfter(val!, startDate!)) || "Must Be After Start Date";
                    },
                  },
                }}
                render={({ field }) => (
                  <DatePicker
                    dateFormat="dd/MM/yyyy H:mm"
                    selected={field.value}
                    showTimeInput
                    onChange={(date: Date) => field.onChange(date)}
                    minDate={startDate}
                  />
                )}
              />
              <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.regions}>
              <FormLabel htmlFor="regions">{t("Regions")}</FormLabel>
              <Controller
                name="regions"
                control={control}
                rules={{
                  validate: (val) => val.length >= 1,
                }}
                render={({ field }) => {
                  return (
                    <ReactSelect
                      isMulti
                      {...field}
                      onChange={(val) => {
                        if (val.map((item) => item.id).includes("all")) {
                          field.onChange(QRegions.data);
                        } else {
                          field.onChange(val);
                        }
                      }}
                      getOptionLabel={(option: IdName) => option.name}
                      getOptionValue={(option: IdName) => option.id}
                      options={QRegions.data ? [{ id: "all", name: t("Select All") }, ...QRegions.data] : []}
                    />
                  );
                }}
              />
              <FormErrorMessage>{errors?.regions?.message}</FormErrorMessage>
            </FormControl>
          </VStack>

          <Box>
            <ButtonGroup display="flex" justifyContent="flex-end" alignSelf="flex-end" mt={4}>
              <Button onClick={handleSubmit(onSubmitDraft)} alignSelf="flex-end" type="submit">
                {t("Save as draft")}
              </Button>
              <Button onClick={handleSubmit(onSubmit)} alignSelf="flex-end" colorScheme="blue" type="submit">
                {t("Submit")}
              </Button>
            </ButtonGroup>
          </Box>
        </form>
      </Box>
    </>
  );
};

export { AddEvent };
