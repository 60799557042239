import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "lib/axios";
import { IdName } from "types";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useErrorToast } from "hooks/useErrorToast";
import { AxiosError } from "axios";

function useRegions() {
  return useQuery(["Regions"], (): Promise<IdName[]> => axios.get("Region"));
}

function useCreateRegion() {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((region: Partial<IdName>) => axios.post("Region", region), {
    onSuccess: () => {
      toast({
        title: t("Region Created"),
        description: t("The Region has been created."),
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      queryClient.invalidateQueries(["Regions"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}
function useEditRegion() {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((region: IdName) => axios.put(`Region/${region.id}`, region), {
    onSuccess: () => {
      toast({
        title: t("Region Edited"),
        description: t("The Region has been updated."),
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      queryClient.invalidateQueries(["Regions"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}
function useDeleteRegion() {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  return useMutation((region: IdName) => axios.delete(`Region/${region.id}`), {
    onSuccess: () => {
      toast({
        title: t("Region Deleted"),
        description: t("The Region has been deleted."),
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      queryClient.invalidateQueries(["Regions"]);
    },
    onError: (err: AxiosError) => {
      errorToast(err);
    },
  });
}

export { useRegions, useCreateRegion, useEditRegion, useDeleteRegion };
