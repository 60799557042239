import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import React from "react";
import { UseMutationResult } from "react-query";
import { useTranslation } from "react-i18next";

interface Props<T> {
  deleteKey: T | null;
  handleClose: () => void;
  entity: string;
  deleteFunction: UseMutationResult<AxiosResponse<any>, unknown, T, unknown>;
}

const ConfirmDeleteModal = <T extends string | object>({
  deleteKey,
  entity,
  handleClose,
  deleteFunction,
}: Props<T>) => {
  const { t } = useTranslation();
  function handleDelete() {
    if (deleteKey) {
      deleteFunction.mutate(deleteKey, {
        onSuccess: () => {
          handleClose();
        },
      });
    }
  }
  return (
    <Modal isOpen={!!deleteKey} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t("Delete")} {entity}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {t("Are You sure you wish to delete")} {entity}?
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" mr={3} onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button onClick={handleDelete} colorScheme="blue" variant="ghost">
            {t("Delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { ConfirmDeleteModal };
