import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Providers from "./Providers/Providers";
import "locales/i18n";
import Hotjar from "@hotjar/browser";

Hotjar.init(process.env.REACT_APP_HOTJAR_SITE_ID as unknown as number, 6, {
  debug: process.env.NODE_ENV === "development",
});

// Extend the existing window interface with our new property
declare global {
  interface Window {
    gtag: any;
  }
}

// window.gtag = window.gtag || {};
// window.gtag("config", process.env.REACT_APP_GA_TAG);

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
