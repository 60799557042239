import React from "react";
import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";

interface Props extends SimpleGridProps {
  children: React.ReactNode;
}
function DefaultGrid({ children, ...gridProps }: Props) {
  return (
    <SimpleGrid
      gridTemplateColumns="repeat( auto-fill, minmax(250px, 1fr) )"
      spacing="8"
      alignItems="center"
      {...gridProps}
    >
      {children}
    </SimpleGrid>
  );
}

export { DefaultGrid };
