import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import { useCompanyDocuments } from "api/company";
import { LoadingError } from "components/LoadingError";
import { SearchInput } from "components/SearchInput";
import { useIsOwner } from "hooks/useIsOwner";
import React from "react";
import { useTranslation } from "react-i18next";
import { ICompanyDocument } from "types";
import { AddDocumentForm } from "../../../components/Entitites/document/AddDocument";
import { DocumentGrid } from "./DocumentsGrid";
import { DocumentsTable } from "./DocumentsTable";

interface Props {
  companyId: string;
}

function filterDocuments(doc: ICompanyDocument, filter: string): boolean {
  let lowFilter = filter.toLowerCase();
  return doc.title.toLowerCase().includes(lowFilter) || doc.description.toLowerCase().includes(lowFilter);
}

const CompanyDocuments = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const [isCreatingNew, setIsCreatingNew] = React.useState(false);
  const QDocuments = useCompanyDocuments(companyId);
  const [filter, setFilter] = React.useState("");
  const [tableView] = React.useReducer((s) => !s, true);
  const isOwner = useIsOwner(companyId);
  return (
    <div id="supplier-information-wrapper">
      {QDocuments.isLoading || QDocuments.isIdle ? (
        <Spinner />
      ) : QDocuments.isError ? (
        <LoadingError />
      ) : tableView ? (
        <>
          <Flex justifyContent="space-between">
            <SearchInput value={filter} handleChange={setFilter} />
            {isOwner ? (
              isCreatingNew ? (
                <Button onClick={() => setIsCreatingNew(false)}>{t("Cancel")}</Button>
              ) : (
                <Button onClick={() => setIsCreatingNew(true)}>{t("Add Document")}</Button>
              )
            ) : null}
          </Flex>
          <Box mt={4}>
            <DocumentsTable
              companyId={companyId}
              data={QDocuments.data.filter((doc) => filterDocuments(doc, filter))}
            />
          </Box>
        </>
      ) : (
        <DocumentGrid data={QDocuments.data} />
      )}
      <AddDocumentForm companyId={companyId} open={isCreatingNew} handleClose={() => setIsCreatingNew(false)} />
    </div>
  );
};

export { CompanyDocuments };
