import { Badge, Flex, Heading, Image, LinkBox, LinkOverlay, Text, Wrap } from "@chakra-ui/react";
import { useCompanyThumbnail } from "api/company";
import { format } from "date-fns";
import logo200 from "Layout/ABF_Logo_200.png";
import React from "react";
import { Link } from "react-router-dom";
import { ERoutes } from "Router/types";
import { CompanyTypes, INewsFeedItemSimple } from "types";

interface Props {
  post: INewsFeedItemSimple;
}

export const NewsPostSimple = ({ post }: Props) => {
  const formattedDate = format(new Date(post.postDateTime), "dd/MM/yyyy");
  const QThumbnail = useCompanyThumbnail(post.companyId);
  const fallbackImg = QThumbnail.data || logo200;
  return (
    <LinkBox
      as="article"
      shadow={{ sm: "base" }}
      rounded={{ sm: "md" }}
      overflow="hidden"
      transition="all 0.2s"
      _hover={{ shadow: { sm: "lg" } }}
    >
      <Flex direction="column" justifyContent="flex-end" height="100%">
        <Image height="60" objectFit="scale-down" alt={post.title} src={post.postThumbnail} fallbackSrc={fallbackImg} />

        <Flex direction="column" px={{ sm: "6" }} py="5">
          <Heading as="h3" size="sm" mb="1" lineHeight="base">
            <LinkOverlay as={Link} to={`/newsfeed/${post.id}`}>
              {post.title}
            </LinkOverlay>
          </Heading>
          {post.companyType.id === CompanyTypes.Supplier ? (
            <Link to={`${ERoutes.supplier}/${post.companyId}`}>
              <Heading as="h4" size="xs" mb="2" _hover={{ textDecor: "underline" }}>
                {post.companyName}
              </Heading>
            </Link>
          ) : (
            <Heading as="h4" size="xs" mb="2">
              {post.companyName}
            </Heading>
          )}
          <Text noOfLines={2} mb="4">
            {post.description}
          </Text>
          <Flex align="baseline" justify="space-between" fontSize="sm">
            <Text>By {post.author.name}</Text>
            <Text display="inline-block" me="2" opacity={0.8}>
              {formattedDate}
            </Text>
          </Flex>
          <Wrap spacing="2" alignItems="flex-end" mt="2">
            {post.regions.map((region) => (
              <Badge
                key={region.id}
                textTransform="uppercase"
                letterSpacing="wider"
                fontSize="xs"
                fontWeight="semibold"
                color="gray.500"
              >
                {region.name}
              </Badge>
            ))}
          </Wrap>
        </Flex>
      </Flex>
    </LinkBox>
  );
};
