import { useUserSelf } from "api/user";
import { LoadingError } from "components/LoadingError";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TermsModal } from "components/TermsModal";
import React from "react";

interface Props {
  children: React.ReactNode;
}

const LegalProvider = (props: Props) => {
  const QUser = useUserSelf();
  if (QUser.isLoading) return <LoadingSpinner />;
  if (!QUser.isSuccess) return <LoadingError />;

  if (QUser.data.termsAccepted) return <>{props.children}</>;
  return <TermsModal isOpen={true} />;
};

export default LegalProvider;
