import { Box, Input } from "@chakra-ui/react";
import { Column, Table } from "@tanstack/react-table";
import { DatePicker } from "./DatePicker";

export function Filter<T>({ column, table }: { column: Column<T, unknown>; table: Table<T> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  const columnFilterValue = column.getFilterValue();
  if (firstValue instanceof Date) {
    return (
      <Box minW={240} fontSize="small" fontWeight={"normal"}>
        <DatePicker
          onChange={(dates) => {
            column.setFilterValue(dates);
          }}
          isClearable
          dateFormat="dd/MM/yyyy"
          placeholderText="Select date range"
          startDate={(columnFilterValue as [Date | null, Date | null])?.[0] ?? null}
          endDate={(columnFilterValue as [Date | null, Date | null])?.[1] ?? null}
          selectsRange={true}
        />
      </Box>
    );
  }

  return (
    <Box minW={160}>
      <Input
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(e) => {
          column.setFilterValue(e.target.value);
        }}
        placeholder={`Search...`}
      />
    </Box>
  );
}
