import React from "react";

type Mode = "create" | "edit" | "delete" | "idle";

interface SelectionState<T> {
  mode: Mode;
  selection: T | null;
}

export type SelectionAction<T> =
  | { type: "create" }
  | { type: "edit"; payload: T }
  | { type: "delete"; payload: T }
  | { type: "reset" };

type CrudReducer<T> = React.Reducer<SelectionState<T>, SelectionAction<T>>;

function upsertReducer<T>(s: SelectionState<T>, action: SelectionAction<T>): SelectionState<T> {
  switch (action.type) {
    case "create":
      return { mode: "create", selection: null };
    case "edit":
      return { mode: "edit", selection: action.payload };
    case "delete":
      return { mode: "delete", selection: action.payload };
    case "reset":
      return { mode: "idle", selection: null };
    default:
      throw new Error("Unsupported action provided");
  }
}

export function useSelection<T>(initialArg: T | null) {
  return React.useReducer<CrudReducer<T>, T | null>(upsertReducer, initialArg, (initialValue) => ({
    mode: "idle",
    selection: initialValue,
  }));
}
