import { Button, Heading, HStack } from "@chakra-ui/react";
import { useCompanyContacts } from "api/company";
import { LoadingSpinner } from "components/LoadingSpinner";
import { SearchInput } from "components/SearchInput";
import { useIsOwner } from "hooks/useIsOwner";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ContactTable } from "./ContactsTable";
import AddContact from "../../../components/Entitites/contact/AddContact";
import AddContactModal from "../../../components/Entitites/contact/AddContactModal";
import { ICompanyContact } from "types";
interface Props {
  companyId: string;
}

function filterContacts(contact: ICompanyContact, filter: string): boolean {
  let lowFilter = filter.toLowerCase();
  return (
    contact.geography.toLowerCase().includes(lowFilter) ||
    contact.name.toLowerCase().includes(lowFilter) ||
    contact.emailAddress.toLowerCase().includes(lowFilter) ||
    contact.jobTitle.toLowerCase().includes(lowFilter) ||
    contact.telephone.includes(lowFilter)
  );
}

const SupplierContacts = ({ companyId }: Props) => {
  const QCompanyContacts = useCompanyContacts(companyId);
  const [filter, setFilter] = React.useState("");
  const [isCreatingNew, setIsCreatingNew] = React.useState(false);
  const isOwnProfile = useIsOwner(companyId);
  const { t } = useTranslation();
  return (
    <div id="supplier-contacts-wrapper">
      <HStack justifyContent={isOwnProfile ? "space-between" : "flex-start"}>
        <SearchInput value={filter} handleChange={setFilter} />
        {isOwnProfile ? (
          isCreatingNew ? (
            <Button onClick={() => setIsCreatingNew(false)}>{t("Cancel")}</Button>
          ) : (
            <Button onClick={() => setIsCreatingNew(true)}>{t("Add Contact")}</Button>
          )
        ) : null}
      </HStack>
      {QCompanyContacts.isLoading ? (
        <LoadingSpinner />
      ) : !QCompanyContacts.isSuccess ? (
        <Heading>Something went wrong</Heading>
      ) : (
        <ContactTable
          companyId={companyId}
          data={QCompanyContacts.data.filter((contact) => filterContacts(contact, filter))}
        />
      )}
      <AddContactModal companyId={companyId} open={isCreatingNew} handleClose={() => setIsCreatingNew(false)} />
    </div>
  );
};
export default SupplierContacts;
