import { Box, Button, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}

export function ErrorFallback({ error, resetErrorBoundary }: Props) {
  const { t } = useTranslation();
  return (
    <Box w="full" h="100%" display="flex" justifyContent="center" alignItems="center" bg="gray.200">
      <Box role="alert" bg="white" borderRadius="xl" p="4">
        <Text>Something went wrong:</Text>
        <pre>{error.message}</pre>
        <Button onClick={resetErrorBoundary}>{t("Refresh")}</Button>
      </Box>
    </Box>
  );
}
