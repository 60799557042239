import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ButtonGroup,
  VStack,
} from "@chakra-ui/react";
import { useCreateContact } from "api/contacts";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  companyId: string;
  open: boolean;
  handleClose: () => void;
}

interface FormTypes {
  companyId: string;
  name: string;
  jobTitle: string;
  emailAddress: string;
  geography: string;
  telephone: string;
}

const AddContactModal = ({ companyId, open, handleClose }: Props) => {
  const { t } = useTranslation();
  const createContact = useCreateContact();

  const { isOpen, onClose } = useDisclosure({ isOpen: open, onClose: handleClose });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: {
      companyId,
      name: "",
      jobTitle: "",
      emailAddress: "",
      geography: "",
      telephone: "",
    },
  });

  React.useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);
  function onSubmit(data: FormTypes) {
    createContact.mutate(
      { ...data, isDraft: false },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  }
  function onSubmitDraft(data: FormTypes) {
    createContact.mutate(
      { ...data, isDraft: true },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  }

  return (
    <>
      <Modal isCentered isOpen={isOpen} size="lg" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Add Contact")}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <VStack width="full" spacing="4">
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel htmlFor="name">{t("Name")}</FormLabel>
                  <Input {...register("name", { required: "Name is required" })} id="name" />
                  <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.emailAddress}>
                  <FormLabel htmlFor="email">{t("Email")}</FormLabel>
                  <Input {...register("emailAddress", { required: "Email is required" })} id="email" />
                  <FormErrorMessage>{errors?.emailAddress?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.telephone}>
                  <FormLabel htmlFor="telephone">{t("Telephone")}</FormLabel>
                  <Input {...register("telephone", { required: t("Required") as string })} id="telephone" />
                  <FormErrorMessage>{errors?.telephone?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.jobTitle}>
                  <FormLabel htmlFor="title">{t("Job Title")}</FormLabel>
                  <Input {...register("jobTitle", { required: "Title is required" })} id="title" />
                  <FormErrorMessage>{errors?.jobTitle?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.geography}>
                  <FormLabel htmlFor="geography">{t("Region")}</FormLabel>
                  <Input
                    {...register("geography", { required: t("Geography is required") as string })}
                    id="geography"
                  ></Input>
                  <FormErrorMessage>{!!errors?.geography?.message}</FormErrorMessage>
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup justifyContent="flex-end" alignSelf="flex-end" mt={4}>
                <Button onClick={handleSubmit(onSubmitDraft)} alignSelf="flex-end" type="submit">
                  {t("Save as draft")}
                </Button>
                <Button onClick={handleSubmit(onSubmit)} alignSelf="flex-end" colorScheme="blue" type="submit">
                  {t("Submit")}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddContactModal;
