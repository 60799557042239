import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useCreateNewsPost } from "api/newsfeed";
import { useRegions } from "api/regions";

import { resizeImage } from "lib/resizeImage";
import { Base64ImageUpload } from "pages/admin/Company/Base64ImageUploadRHF";
import React from "react";
import { Controller, NestedValue, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import SunEditor from "suneditor-react";
import { IdName } from "types";
import SunEditorCore from "suneditor/src/lib/core";
import lang from "suneditor-react/dist/types/lang";

interface Props {
  companyId: string;
  handleClose: () => void;
}

interface FormTypes {
  title: string;
  companyId: string;
  content: string;
  description: string;
  isDraft: boolean;
  regions: NestedValue<{ id: string; name: string }[]>;
  thumbnailBase64: string;
}

export const AddNewsPost = ({ companyId, handleClose }: Props) => {
  let editorRef = React.useRef<SunEditorCore | null>(null);

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editorRef.current = sunEditor;
  };
  const QRegions = useRegions();
  const createPost = useCreateNewsPost();
  const { t, i18n } = useTranslation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: {
      title: "",
      companyId,
      content: "",
      description: "",
      isDraft: true,
      regions: [],
      thumbnailBase64: "",
    },
  });

  function onSubmit(data: FormTypes) {
    createPost.mutate(
      {
        ...data,
        regions: data.regions.map((region) => region.id),
        isDraft: false,
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  }
  function onClose() {
    reset();
    editorRef.current?.setContents("");
    handleClose();
  }

  function onSubmitDraft(data: FormTypes) {
    createPost.mutate(
      {
        ...data,
        regions: data.regions.map((region) => region.id),
        isDraft: true,
      },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  }

  React.useEffect(() => {
    reset();
    return () => {
      reset();
    };
  }, [reset]);

  const thumbnail = watch("thumbnailBase64");
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack width="full" spacing="4">
          <Base64ImageUpload name="thumbnailBase64" control={control} imageWidth={800} />
          {thumbnail ? <Image src={thumbnail} /> : null}
          <FormControl isInvalid={!!errors.title}>
            <FormLabel htmlFor="title">{t("Title")}</FormLabel>
            <Input {...register("title", { required: "title is required" })} id="title" />
            <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.description}>
            <FormLabel htmlFor="description">{t("Description")}</FormLabel>
            <Textarea {...register("description")} id="description" />
            <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.content}>
            <FormLabel htmlFor="content">{t("Content")}</FormLabel>
            <Controller
              control={control}
              name="content"
              render={({ field }) => {
                return (
                  <SunEditor
                    lang={i18n.language as lang}
                    height="100%"
                    setOptions={{
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize", "formatBlock"],
                        [
                          "bold",
                          "underline",
                          "italic",
                          "strike",
                          "subscript",
                          "superscript",
                          "fontColor",
                          "hiliteColor",
                          "removeFormat",
                        ],
                        ["align", "horizontalRule", "outdent", "indent", "list"],
                        ["table", "link", "image", "video"],
                      ],
                    }}
                    onImageUploadBefore={(files, _, uploadHandler) => {
                      resizeImage(files, uploadHandler);
                    }}
                    autoFocus={false}
                    defaultValue={""}
                    {...field}
                    getSunEditorInstance={getSunEditorInstance}
                  />
                );
              }}
            />
            <FormErrorMessage>{errors?.content?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.regions}>
            <FormLabel htmlFor="regions">{t("Regions")}</FormLabel>
            <Controller
              name="regions"
              control={control}
              rules={{
                validate: (val) => val.length >= 1,
              }}
              render={({ field }) => (
                <ReactSelect
                  isMulti
                  {...field}
                  onChange={(val) => {
                    if (val.map((item) => item.id).includes("all")) {
                      field.onChange(QRegions.data);
                    } else {
                      field.onChange(val);
                    }
                  }}
                  getOptionLabel={(option: IdName) => option.name}
                  getOptionValue={(option: IdName) => option.id}
                  options={QRegions.data ? [{ id: "all", name: t("Select All") }, ...QRegions.data] : []}
                />
              )}
            />
            <FormErrorMessage>{errors?.regions?.message}</FormErrorMessage>
          </FormControl>
        </VStack>
        <Box display="flex" justifyContent="flex-end" mt="2">
          <ButtonGroup justifyContent="flex-end" alignSelf="flex-end" mt={4}>
            <Button onClick={handleSubmit(onSubmitDraft)} alignSelf="flex-end" type="submit">
              {t("Save as draft")}
            </Button>
            <Button onClick={handleSubmit(onSubmit)} alignSelf="flex-end" colorScheme="blue" type="submit">
              {t("Submit")}
            </Button>
          </ButtonGroup>
        </Box>
      </form>
    </>
  );
};
