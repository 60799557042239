import originalAxios from "axios";
import i18n from "locales/i18n";
export const baseURL = process.env.REACT_APP_API_BASE_URL;

const axios = originalAxios.create({
  baseURL,
  timeout: 30000,
});
axios.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers["Accept-Language"] = i18n.language;
  return config;
});
axios.interceptors.response.use((value) => value.data);

export default axios;
