import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useMemberSites } from "api/membersite";
import { useUserSelf } from "api/user";
import { IValueTracker, useCreateValueTrackerItem, useUpdateValueTrackerItem } from "api/valueTracker";
import { AxiosError } from "axios";
import { DatePicker } from "components/DatePicker";
import { addHours } from "date-fns";
import { useErrorToast } from "hooks/useErrorToast";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  valueItem: IValueTracker | null;
}

export interface FormTypes {
  supplierId: string;
  memberSiteId: string;
  initiativeDescription: string;
  startDate: Date | null;
  endDate: Date | null;
  benefit: string | number;
  addedValue: string;
}

export const UpsertValueItem = ({ isOpen, onClose, valueItem }: Props) => {
  const QUser = useUserSelf();
  const { t } = useTranslation();
  const QMemberSites = useMemberSites();
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormTypes>({
    defaultValues: {
      supplierId: QUser.data?.userCompany?.companyId ?? "",
      memberSiteId: valueItem?.memberSiteId ?? "",
      initiativeDescription: valueItem?.initiativeDescription ?? "",
      startDate: valueItem?.startDate ? new Date(valueItem.startDate) : null,
      endDate: valueItem?.endDate ? new Date(valueItem.endDate) : null,
      benefit: valueItem?.benefit ?? "",
      addedValue: valueItem?.addedValue ?? "",
    },
  });

  const createValueItem = useCreateValueTrackerItem();
  const updateValueItem = useUpdateValueTrackerItem();
  React.useEffect(() => {
    const existingValues = getValues();
    if (QUser.data?.userCompany?.companyId) {
      reset({ ...existingValues, supplierId: QUser.data?.userCompany?.companyId });
    }
  }, [QUser.data, reset, getValues]);

  const startDate = watch("startDate");

  const errorToast = useErrorToast();
  const onSubmit: SubmitHandler<FormTypes> = (data) => {
    if (!!valueItem) {
      updateValueItem.mutate(
        {
          id: valueItem.id,
          ...data,
          startDate: data.startDate!,
          endDate: data.endDate!,
          benefit: data.benefit ? Number(data.benefit) : null,
          addedValue: data.addedValue || null,
        },
        {
          onSuccess: () => {
            onClose();
          },
          onError: (error) => {
            errorToast(error as AxiosError);
          },
        }
      );
      // this is edit version
    } else {
      //this is create version
      createValueItem.mutate(
        {
          ...data,
          startDate: data.startDate!,
          endDate: data.endDate!,
          benefit: data.benefit ? Number(data.benefit) : null,
          addedValue: data.addedValue || null,
        },
        {
          onSuccess: () => {
            onClose();
          },
          onError: (error) => {
            errorToast(error as AxiosError);
          },
        }
      );
    }
  };
  function resetafterClose() {
    reset();
  }

  const submitDisabled = createValueItem.isLoading || updateValueItem.isLoading;

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      size="xl"
      onClose={onClose}
      onCloseComplete={resetafterClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>{valueItem?.id ? t("Update Value Item") : t("Create Value Item")}</ModalHeader>
          <ModalCloseButton />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <VStack width="full" spacing="4">
                  <FormControl isInvalid={!!errors.memberSiteId}>
                    <FormLabel htmlFor="memberSiteId">{t("Site")}</FormLabel>
                    <Controller
                      control={control}
                      name="memberSiteId"
                      rules={{ required: t("Required") as string }}
                      render={({ field }) => (
                        <ReactSelect
                          defaultValue={
                            valueItem?.memberSiteId
                              ? { id: valueItem.memberSiteId, siteName: valueItem.memberSiteName }
                              : null
                          }
                          options={QMemberSites.data}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.siteName}
                          placeholder={t("Select Site")}
                          isDisabled={createValueItem.isLoading || updateValueItem.isLoading}
                          isLoading={QMemberSites.isLoading}
                          isSearchable
                          onChange={(option) => {
                            field.onChange(option?.id);
                          }}
                        />
                      )}
                    />

                    <FormErrorMessage>{errors?.memberSiteId?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.initiativeDescription}>
                    <FormLabel htmlFor="initiativeDescription">{t("Description")}</FormLabel>
                    <Input
                      {...register("initiativeDescription", { required: t("Required") as string })}
                      id="initiativeDescription"
                    />
                    <FormErrorMessage>{errors?.initiativeDescription?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.startDate}>
                    <FormLabel htmlFor="startDate">{t("Start")}</FormLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{
                        required: t("Required") as string,
                      }}
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={field.value}
                          onChange={(date: Date) => {
                            field.onChange(date);
                          }}
                        />
                      )}
                    />
                    <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.endDate}>
                    <FormLabel htmlFor="endDate">{t("End")}</FormLabel>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{
                        required: t("Required") as string,
                      }}
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={field.value}
                          onChange={(date: Date) => field.onChange(date)}
                          minDate={startDate}
                        />
                      )}
                    />
                    <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.benefit}>
                    <FormLabel htmlFor="benefit">{t("Benefit")}</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none" children={"$"} />
                      <Input
                        {...register("benefit", {
                          setValueAs: (v) => (v === "" ? null : Number(v)),
                          validate: (v) =>
                            v === null || v === "" || !Number.isNaN(Number(v))
                              ? undefined
                              : (t("Must be a number") as string),
                        })}
                      />
                    </InputGroup>

                    <FormErrorMessage>{errors?.benefit?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.addedValue}>
                    <FormLabel htmlFor="regions">{t("Added Value")}</FormLabel>
                    <Input {...register("addedValue")} />
                    <FormErrorMessage>{errors?.addedValue?.message}</FormErrorMessage>
                  </FormControl>
                </VStack>

                <Box mt={1} mb={2}>
                  <ButtonGroup display="flex" justifyContent="flex-end" alignSelf="flex-end" mt={4}>
                    <Button onClick={onClose} alignSelf="flex-end" colorScheme="red">
                      {t("Cancel")}
                    </Button>
                    <Button disabled={submitDisabled} type="submit" alignSelf="flex-end" colorScheme="blue">
                      {valueItem?.id ? t("Update") : t("Submit")}
                    </Button>
                  </ButtonGroup>
                </Box>
              </ModalBody>
            </form>
          </Box>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
