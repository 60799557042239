import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useCreateDocument, useDocumentSettings } from "api/document";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

type FormTypes = {
  companyId: string;
  title: string;
  description: string;
  fileName: string;
};

interface ModalProps {
  companyId: string;
  open: boolean;
  handleClose: () => void;
}

function AddDocumentForm({ companyId, open, handleClose }: ModalProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const { isOpen, onClose } = useDisclosure({ isOpen: open, onClose: handleClose });
  const QDocumentSettings = useDocumentSettings();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: {
      companyId,
      title: "",
      description: "",
      fileName: "",
    },
  });

  const fileName = useWatch({
    control,
    name: "fileName",
    defaultValue: "",
  });

  React.useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  function handleFileSelect(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length && QDocumentSettings.data) {
      if (e.target.files[0].size > QDocumentSettings.data?.maxSizeBytes) {
        toast({
          position: "bottom-left",
          status: "error",
          title: t("File size too large"),
        });
      }
      setSelectedFile(e.target.files[0]);
      setValue("fileName", e.target.files[0].name);
    }
  }

  const addDoc = useCreateDocument();
  function onSubmit(data: FormTypes) {
    if (selectedFile) {
      const formItems = new FormData();
      formItems.append("companyId", data.companyId);
      formItems.append("title", data.title);
      formItems.append("description", data.description);
      formItems.append("fileName", data.fileName);
      formItems.append("file", selectedFile);
      addDoc.mutate(formItems, {
        onSuccess: () => {
          handleClose();
        },
      });
    }
  }

  return (
    <Modal isCentered isOpen={isOpen} size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Add Document")}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack width="full" spacing="4">
              <FormControl isInvalid={!!errors.title}>
                <FormLabel htmlFor="title">{t("Title")}</FormLabel>
                <Input {...register("title", { required: t("Required") as string })} id="title" />
                <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.description}>
                <FormLabel htmlFor="description">{t("Description")}</FormLabel>
                <Input {...register("description", { required: t("Required") as string })} id="description" />
                <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.fileName} isRequired width="auto" alignSelf="flex-start">
                <FormLabel htmlFor="imageUpload">
                  <input
                    accept={QDocumentSettings.data?.fileExtensionWhiteList.join(", ")}
                    type="file"
                    onChange={handleFileSelect}
                    ref={inputRef}
                    id="imageUpload"
                    required
                    style={{ display: "none" }}
                  ></input>
                  <Button onClick={() => inputRef.current?.click()}>{t("Choose File")}</Button>
                </FormLabel>
                <FormHelperText>
                  {fileName ? fileName : `Max File size: ${QDocumentSettings.data?.maxSizeMb} mb`}
                </FormHelperText>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button type="submit" colorScheme="blue" disabled={addDoc.isLoading}>
              {t("Add")}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
export { AddDocumentForm };
