import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { ICompanyProfile } from "types";
// import sanitizeHtml from "sanitize-html";

interface Props {
  profile?: ICompanyProfile;
}

const DisplayProfile = ({ profile }: Props) => {
  const { t } = useTranslation();
  const profileMarkup = profile?.content ? profile.content : "";
  return (
    <>
      {profileMarkup ? (
        <Box className="sun-editor" style={{ border: "none" }} boxShadow="lg" border="ActiveBorder" borderRadius="3xl">
          <Box className="se-wrapper">
            <Box
              className="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"
              dangerouslySetInnerHTML={{ __html: profileMarkup }}
            ></Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Heading>{t("No Profile yet")}</Heading>
        </Box>
      )}
    </>
  );
};

export { DisplayProfile };
