import { DownloadIcon } from "@chakra-ui/icons";
import { Box, VStack, Heading, HStack, IconButton, Text } from "@chakra-ui/react";
import { ICompanyDocument } from "types";

interface Props {
  data: ICompanyDocument[];
}

function DocumentGrid({ data }: Props) {
  return (
    <Box display="grid" gridTemplateColumns="repeat(4,1fr)">
      {data.map((document) => (
        <Document document={document} />
      ))}
    </Box>
  );
}

interface CompanyDocumentProps {
  document: ICompanyDocument;
}

function Document({ document }: CompanyDocumentProps) {
  return (
    <Box>
      <VStack>
        <Heading>{document.title}</Heading>
        <Text>{document.description}</Text>
        <HStack>
          Download{" "}
          <IconButton aria-label={`download-${document.title}`}>
            <DownloadIcon />
          </IconButton>
        </HStack>
      </VStack>
    </Box>
  );
}

export { DocumentGrid };
