import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useUserSelf } from "api/user";
import { IValueTracker, useDeleteValueTrackerItem } from "api/valueTracker";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  valueItem: IValueTracker | null;
}

export const ConfirmDeleteValueItem = (props: Props) => {
  const QUser = useUserSelf();
  const { t, i18n } = useTranslation();
  const deleteItem = useDeleteValueTrackerItem();
  function confirmDelete() {
    if (!props.valueItem) {
      throw new Error("Something went wrong");
    } else {
      deleteItem.mutate(props.valueItem.id, {
        onSuccess: () => {
          props.onClose();
        },
      });
    }
  }

  return (
    <Modal isCentered isOpen={props.isOpen} size="lg" onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>{t("Delete Value Item")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you wish to delete {props.valueItem?.initiativeDescription}?
            <Box>
              <ButtonGroup display="flex" justifyContent="flex-end" alignSelf="flex-end" mt={4}>
                <Button onClick={confirmDelete} alignSelf="flex-end" colorScheme="red">
                  {t("Delete")}
                </Button>
              </ButtonGroup>
            </Box>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
