import React from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
const DatePicker = ({ ...props }: ReactDatePickerProps) => {
  return (
    <div className={"light-theme"}>
      <ReactDatePicker className="react-datapicker__input-text" {...props} />
    </div>
  );
};

export { DatePicker };
