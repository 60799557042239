import { useMsal, useAccount } from "@azure/msal-react";
import { Button, Heading, Text } from "@chakra-ui/react";
import { useUserSelf } from "api/user";
import { CardContainer } from "components/CardContainer";
import { FullPageSplash } from "components/FullPageSplash";
import { LoadingSpinner } from "components/LoadingSpinner";
import * as React from "react";
import { AxiosError } from "axios";

interface Props {}

function UserProvider(props: React.PropsWithChildren<Props>) {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const logout = async () => {
    if (account?.homeAccountId) {
      const currentAccount = instance.getAccountByHomeId(account?.homeAccountId);
      await instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: "/",
      });
    }
  };

  const QUserSelf = useUserSelf();
  if (QUserSelf.isLoading || QUserSelf.isIdle) {
    return (
      <FullPageSplash>
        <CardContainer>
          <LoadingSpinner />
        </CardContainer>
      </FullPageSplash>
    );
  }
  if (QUserSelf.isError) {
    const errorReason: AxiosError = QUserSelf.error as AxiosError;
    return (
      <FullPageSplash>
        <CardContainer>
          <Heading>Error:</Heading>
          {errorReason.response?.status === 402 ? (
            <Text>Please ensure your account has an allocated licence.</Text>
          ) : (
            <Text>Something went wrong when fetching your account, please try again</Text>
          )}

          <Button
            onClick={logout}
            bg="brand.red"
            color="white"
            _hover={{
              bg: "#D07681",
            }}
          >
            Log out
          </Button>
        </CardContainer>
      </FullPageSplash>
    );
  }
  return <>{props.children}</>;
}

export { UserProvider };
