import { useMsal, useAccount } from "@azure/msal-react";
import axios from "lib/axios";
import { PropsWithChildren } from "react";
import { loginRequest } from "./utils/config";
import React from "react";
import { Box } from "@chakra-ui/react";
import { LoadingSpinner } from "components/LoadingSpinner";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

function AuthenticatedApiWrapper(props: PropsWithChildren<{}>) {
  const { instance, accounts } = useMsal();
  const [headersSet, setHeadersSet] = React.useState(false);
  const account = useAccount(accounts[0] || {});

  const getToken = () => {
    if (account) {
      return instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((res) => res.accessToken)
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            if (account) {
              instance.acquireTokenRedirect({ ...loginRequest, account });
            }
          }
          console.log(error);
        });
    }
  };

  React.useEffect(() => {
    const createInterceptor = async () => {
      console.log("CREATING INTERCEPTER");
      axios.interceptors.request.use(
        // if !isAuthenticated ???
        async (config) => {
          const token = await getToken();
          // @ts-ignore
          config.headers.Authorization = token ? `Bearer ${token}` : "";
          // @ts-ignore
          config.headers["X-Intellync.CollaborationHub.TenantId"] =
            //@ts-ignore
            account?.idTokenClaims?.extension_CollaborationHubTenancies || "";
          return config;
        },
        (error) => Promise.reject(error)
      );
      setHeadersSet(true);
    };
    createInterceptor();
  }, []);

  return (
    <>
      {headersSet ? (
        props.children
      ) : (
        <Box width="full" height="100vh" display="flex" justifyContent="center" alignItems="center">
          <LoadingSpinner />{" "}
        </Box>
      )}
    </>
  );
}

export { AuthenticatedApiWrapper };
