import React, { PropsWithChildren } from "react";
import { Container } from "@chakra-ui/react";

interface Props {}

export const CardContainer = (props: PropsWithChildren<Props>) => {
  return (
    <Container bg="white" p="8" rounded="md">
      {props.children}
    </Container>
  );
};
